import http from "./httpService";

export function getNewPhotos(perPage, currentPage) {
  return http.get(
    `/photos?isApproved=false&perPage=${perPage}&currentPage=${currentPage}`
  );
}

export function approvePhoto(body) {
  return http.post(`/published-photos`, body);
}

export function rejectPhoto(body) {
  return http.post(`/rejected-photos`, body);
}

export function approveHoroscope(body) {
  return http.post(`published-horoscopePhotos`, body);
}

export function rejectHoroscope(body) {
  return http.post(`/rejected-horoscopePhotos`, body);
}

export function getHoroscopePhotos(perPage, currentPage) {
  return http.get(
    `/profiles/moderatorHoroscopePhotos?isApproved=false&perPage=${perPage}&currentPage=${currentPage}`
  );
}