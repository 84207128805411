import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useModerator } from "../../../context/moderatorContext";
import FullProfileDetails from "../profileRequest/fullProfileDetails";
import {
  deleteProfile,
  getHoldedProfiles,
  getProfile,
  getRequestedProfiles,
  getSoftDeletedProfiles,
  holdProfile,
  publishProfile,
  rejectProfile,
  unholdProfile,
  getMemberDetailsById
} from "./../../../services/profileService";
import "./profiles.css";
import { toast } from "react-toastify";
import ContenLoader from "../../common/contentLoader";

const Profile = (props) => {
  const { myProfile } = useModerator();
  const { setHoldedProfiles, setDeletedProfiles, setRequestedProfiles } =
    useModerator();
  const [profile, setProfile] = useState();
  const [show, setShow] = useState(false);
  const [option, setOption] = useState();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const [member, setMember] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await getProfile(props.location.state.profile.id);
      const member = await getMemberDetailsById(props.location.state.profile.externalId);
      setMember(member.data);
      setProfile(data);
      setLoading(false);
    }
    fetchData();
  }, []);

  const handleHoldOrUnhold = async (id) => {
    if (profile.status === "Holded") {
      const response = await unholdProfile(id, myProfile.id);
      if (response.status === 204) {
        toast.success("This Profile Undo On Hold Successfully!!!");
        const holdedProfilesResponse = await getHoldedProfiles();
        if ((holdedProfilesResponse.status = 200)) {
          setHoldedProfiles(holdedProfilesResponse.data);
        }
        history.replace(`/moderator/member`);
        // setOption("UNHOLD");
        // handleShow();
      }
    } else {
      const response = await holdProfile(id, myProfile.id);
      if (response.status === 201) {
        toast.success("This Profile On Hold Successfully!!!");
        const holdedProfilesResponse = await getHoldedProfiles();
        if ((holdedProfilesResponse.status = 200)) {
          setHoldedProfiles(holdedProfilesResponse.data);
        }
        history.replace(`/moderator/member`);
        // setOption("HOLD");
        // handleShow();
      }
    }
  };

  const handleDelete = async (id) => {
    const response = await deleteProfile(id, myProfile.id);
    if (response.status === 201) {
      toast.success("This Profile Deleted Successfully!!!");
      var today = new Date();
      today.setDate(today.getDate() - 180);
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      let obj = { toDate: date };
      const deletedProfilesResponse = await getSoftDeletedProfiles(obj);
      if ((deletedProfilesResponse.status = 200)) {
        setDeletedProfiles(deletedProfilesResponse.data);
      }
      history.replace(`/moderator/member`);
      // setOption("DELETE");
      // handleShow();
    }
  };

  const handleAccept = async (id) => {
    const response = await publishProfile(id);
    if (response.status == 201) {
      toast.success("This Profile Accepted Successfully!!!");
      const requestedProfilesResponse = await getRequestedProfiles();
      if ((requestedProfilesResponse.status = 200)) {
        setRequestedProfiles(requestedProfilesResponse.data);
      }
      history.replace(`/moderator/member`);
      // setOption("ACCEPT");
      // handleShow();
    }
  };

  const handleReject = async (id) => {
    const response = await rejectProfile(id);
    if (response.status == 201) {
      toast.success("This Profile Rejected Successfully!!!");
      const requestedProfilesResponse = await getRequestedProfiles();
      if ((requestedProfilesResponse.status = 200)) {
        setRequestedProfiles(requestedProfilesResponse.data);
      }
      history.replace(`/moderator/member`);
      // setOption("REJECT");
      // handleShow();
    }
  };

  return (
    <>
      {loading && <ContenLoader />}
      {!loading && (
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Matrimony</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              {option == "UNHOLD" &&
                "Do you want to Undo On Hold this Profile?"}
              {option == "HOLD" && "Do you want to On Hold this Profile?"}
              {option == "DELETE" && "Do you want to delete this Profile?"}
              {option == "ACCEPT" && "Do you want to accept this Profile?"}
              {option == "REJECT" && "Do you want to reject this Profile?"}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  if (option == "UNHOLD") {
                    handleHoldOrUnhold(profile.id);
                  } else if (option == "HOLD") {
                    handleHoldOrUnhold(profile.id);
                  } else if (option == "DELETE") {
                    handleDelete(profile.id);
                  } else if (option == "ACCEPT") {
                    handleAccept(profile.id);
                  } else if (option == "REJECT") {
                    handleReject(profile.id);
                  }
                }}
              >
                Yes
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleClose();
                }}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
          {profile && (
            <div className="home mt-5">
              <div className="col-md-10">
                <FullProfileDetails profile={profile} {...props} member={member} />
                {profile.status != "Await" && (
                  <button
                    className="btn btn-primary"
                    style={{ margin: '5px', backgroundColor: '#571B38', borderColor: '#571B38' }}
                    onClick={() => {
                      if (profile.status == "Active") {
                        setOption("HOLD");
                        handleShow();
                      } else if (profile.status == "Holded") {
                        setOption("UNHOLD");
                        handleShow();
                      }
                    }}
                  >
                    {profile.status == "Active" && " On Hold"}
                    {profile.status == "Holded" && "Undo On Hold"}
                  </button>
                )}
                <br />
                {profile.status != "Await" && (
                  <button
                    className="btn btn-primary"
                    style={{ margin: '5px', backgroundColor: '#571B38', borderColor: '#571B38' }}
                    onClick={() => {
                      setOption("DELETE");
                      handleShow();
                    }}
                  >
                    Delete
                  </button>
                )}
                {profile.status == "Await" && (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setOption("ACCEPT");
                      handleShow();
                    }}
                  >
                    Accept
                  </button>
                )}
                <br />
                {profile.status == "Await" && (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setOption("REJECT");
                      handleShow();
                    }}
                  >
                    Reject
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    history.push({
                      pathname: '/moderator/profileAudit',
                      state: { profileId: profile.id }
                    });
                  }}
                >View audits</button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Profile;
