import http from "./httpService";

export function getProfileAudit(fromDate, toDate) {
  return http.get(`profiles/audits?fromDate=${fromDate}&toDate=${toDate}`);
}

export function getProfileAudits() {
  return http.get(`profiles/audits`);
}

export function getProfileAuditById(id) {
  return http.get(`profiles/audits/getById?userId=${id}`);
}

export function getExpIntAuditById(id) {
  return http.get(`expIntAudit/getById?userId=${id}`);
}

export function getMemberAudits() {
  return http.get(`members/audits`);
}

export function getMemberAudit(From, To) {
  return http.get(`members/audits?fromDate=${From}&toDate=${To}`);
}

export function getMember(id) {
  return http.get(`members/${id}`);
}

export function getAllMembers() {
  return http.get(`/members`);
}
export function getMembers() {
  return http.get(`/members/allMembers`);
}
export function getAttributeAudits() {
  return http.get(`AccessibleLogs/audits`);
}

export function getAttributeAudit(fromDate, toDate) {
  return http.get(`AccessibleLogs/audits?fromDate=${fromDate}&toDate=${toDate}`);
}

export function getExpressedInterestAudits() {
  return http.get(`expIntAudit`);
}
export function getExpressedInterestAudit(fromDate, toDate) {
  return http.get(`expIntAudit?fromDate=${fromDate}&toDate=${toDate}`);
}
//get all profiles
export function getAllProfiles() {
  return http.get(`Profiles/Dashboard/allProfiles`);
}
