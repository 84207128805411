import { Controller } from 'react-hook-form'
import ReactSelect from 'react-select'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: 'red',
    '&:hover': {
      borderColor: 'red',
    },
    focus: {
      borderColor: 'black',
    },
  }),
}

const Select = ({
  name,
  label,
  error,
  options,
  control,
  defaultValue,
  isLoading,
  isMulti,
}) => {
  return (
    <div>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value, name, ref }) => (
          <ReactSelect
            getOptionLabel={(option) => option.value || option.name}
            getOptionValue={(option) => option.id}
            {...{ onChange, onBlur, value, name, ref }}
            options={options}
            isLoading={isLoading}
            isMulti={isMulti}
            styles={error ? customStyles : ''}
            className={'is-invalid'}
          />
        )}
      />
      <div className='invalid-feedback form-contol '>{error}</div>
    </div>
  )
}

export default Select
