import React from "react";
import AboutDetails from "./aboutDetails";
import BasicDetails from "./basicDetails";
import HobbyAndInterestDetails from "./hobbyAndInterestDetails";
import LocationDetails from "./locationDetails";
import PartnerCriterionDetails from "./partnerCriterionDetails";
import ProfessionalDetails from "./professionalDetails";
import FamilyDetails from './familyDetails';
import ContactDetails from "./contactDetails";
import ProfilePhotos from "./ProfilePhotos";
import Grid from '@mui/material/Grid';

const FullProfileDetails = (props) => {
  return (
    <>
      <Grid container spacing={2} className="mt-3">
        <Grid item xs={6}>
          <ProfilePhotos profile={props} {...props} />
          <AboutDetails profile={props} {...props} />
          <BasicDetails profile={props} {...props} />
          <FamilyDetails profile={props} {...props} />
          <HobbyAndInterestDetails profile={props} {...props} />
        </Grid>
        <Grid item xs={6}>
          <ContactDetails member={props} {...props} />
          <ProfessionalDetails profile={props} {...props} />
          <LocationDetails profile={props} {...props} />
          <PartnerCriterionDetails profile={props} {...props} />
        </Grid>
      </Grid>
    </>
  );
};

export default FullProfileDetails;
