import React, { useEffect,useState } from "react";
import { Alert } from "react-bootstrap";
import { useAuth } from "../context/authContext";
import NavBar from "./navBar";
import { useHistory } from "react-router";
import ContentLoader from "./common/contentLoader";
import { useIsAuthenticated } from "@azure/msal-react";
import { getMe } from "../services/userService";
import { MicrosoftLoginAgent } from "../services/auth/msal";

const Home = () => {
  const {
    account,
    setAccount,
    isModerator,
    setIsModerator,
    isModeratorLoading,
  } = useAuth();
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const [loading,setLoading]= useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      if (isAuthenticated) {
        //Populate account (reason: user has been redirected here after login)
        const account = MicrosoftLoginAgent.getAccount();
        setAccount(account);

        //Redirecting user if profile/member exists
        const userResponse = await getMe("me");
        if (userResponse.status == 200) {
          return history.push("/moderator/member");
        }
      }
      setLoading(false)
    }
    fetchData();
  }, [isAuthenticated]);

return (
  <>
  {loading && <ContentLoader className="spinner" />}
  {!loading && (
    <>
      <NavBar />
      <h1 className="text-center">VIVAAKAM</h1>
      {account && (
        <div className="container">
          <Alert show={!isModerator} variant="danger">
            <p>You don't have moderator permissions</p>
          </Alert>
        </div>
      )}
    </>
  )}
</>
);
};

export default Home;
