import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import 'react-toastify/dist/ReactToastify.css'
import AdminDashboard from './adminDashboard'
import AdminHome from './adminHome'
import ModeratorInvitation from './moderatorInvitation'
import UserRoleRequests from './userRoleRequests'
import UserRoleRevocation from './userRoleRevocation'

function Admin() {
  return (
    <>
      <div className='container'>
        <Switch>
          <Route path='/admin/home' component={AdminHome} />
          <Route
            path='/admin/user-role-requests'
            component={UserRoleRequests}
          />
          <Route
            path='/admin/user-role-revocation'
            component={UserRoleRevocation}
          />
          <Route
            path='/admin/moderator-invitation'
            component={ModeratorInvitation}
          />
          <Redirect from='/admin' exact to='/admin/home' />
        </Switch>
      </div>
      <AdminDashboard />
    </>
  )
}

export default Admin
