import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  addAttribute,
  getAttributes,
  getAttributesByStatus,
  getAttributeStatuses,
  updateAttribute,
} from "../services/attributeService";
import { useModerator } from "./moderatorContext";

const AttributesContext = React.createContext();

export const AttributesProvider = ({ children }) => {
  const { myProfile } = useModerator();
  const [atts, setAtts] = useState([]);
  const [attsLoading, setAttsLoading] = useState(true);
  const [attStatus, setAttStatus] = useState([]);
  const [attStatusLoading, setAttStatusLoading] = useState(true);

  /**
   *  Populates the attribute statuses (for dropdowns)
   */
  useEffect(() => {
    async function fetchData() {
      setAttStatusLoading(true);

      const response = await getAttributeStatuses();
      if (response.status === 200) {
        setAttStatus(response.data);
      } else {
        setAttStatus([]);
      }

      setAttStatusLoading(false);
    }
    fetchData();
  }, []);

  /**
   * Searches for Attributes By Status and Updates 'atts' state
   * @param {attCat} (string) Name of category E.g:"Creators"
   * @param {status} (string) status to filter by
   */
  const SearchAtts = async (attCat, status) => {
    if (attCat === null || status === null) return [];
    setAttsLoading(true);

    const response = await getAttributesByStatus(attCat, status);
    if (response.status === 200) {
      setAtts(response.data);
    } else {
      setAtts([]);
    }
    return setAttsLoading(false);
  };

  /**
   * Update Attribute
   * @param {attCat} (string) Name of category E.g:"Creators"
   * @param {attId} (int) Id of attribute
   * @param {updatedBody} Object with name and status;
   */
  const UpdateAtt = async (attCat, attId, updatedBody) => {
    if (attCat === null || attId === null);
    setAttsLoading(true);

    const response = await updateAttribute(attCat, attId, updatedBody, myProfile.id);
    if (response.status === 204) {
      toast.success("Updated Attribute Successfully");
    } else {
      toast.error("Failed to Update Attribute");
    }
    return setAttsLoading(false);
  };

  /**
   * Add New Attribute
   * @param {attCat} (string) Name of category E.g:"Creators"
   * @param {updatedBody} Object with name and status;
   */
  const AddAtt = async (attCat, newBody) => {
    if (attCat === null);
    setAttsLoading(true);

    const response = await addAttribute(attCat, newBody, myProfile.id);
    if (response.status === 201) {
      toast.success("Added Attribute Successfully");
    } else {
      toast.error("Failed to Add Attribute");
    }
    return setAttsLoading(false);
  };

  return (
    <AttributesContext.Provider
      value={{
        atts,
        attsLoading,
        attStatus,
        attStatusLoading,
        SearchAtts,
        UpdateAtt,
        AddAtt,
      }}
    >
      {children}
    </AttributesContext.Provider>
  );
};

export const useAttributes = () => useContext(AttributesContext);
