import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import "./sideBar.css";
import { ExitToApp } from "@material-ui/icons";
import { useAuth } from "../../../../context/authContext";
import { MicrosoftLoginAgent } from "../../../../services/auth/msal";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useModerator } from "../../../../context/moderatorContext";
import ContentLoader from "../../../common/contentLoader";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Collapse from '@mui/material/Collapse';
import StarBorder from '@mui/icons-material/StarBorder';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GavelIcon from '@mui/icons-material/Gavel';

const drawerWidth = 240;

function SideBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { myProfile } = useModerator();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const [open2, setOpen2] = React.useState(true);

  const handleClick2 = () => {
    setOpen2(!open2);
  };


  const { account, setAccount } = useAuth();
  const history = useHistory();

  const onLogout = async () => {
    await MicrosoftLoginAgent.logout();
    const account = MicrosoftLoginAgent.getAccount();
    setAccount(account);
    history.push("/");
  };

  const { pathname } = useLocation();
  const {
    profiles,
    requestedProfiles,
    updateRequests,
    holdedProfiles,
    deletedProfiles,
    photoRequests,
    photoCount,
    moderatorLoading,
    horoscopePhotos
  } = useModerator();

  const drawer = (
    <div>
      <Toolbar style={{ backgroundColor: "#571B38" }}>
        <Typography id="dashboard-topic" variant="h6" noWrap component="div">
          <img
            src="https://www.vivaakam.com/static/media/logo%209.b301f765.png"
            alt="Vivaakam Logo"
            style={{ width: '130px' }}
          />
        </Typography>
      </Toolbar>

      <Divider />
      {/* <List>
        {["Home"].map((text, index) => (
          <ListItem
            className="list-nav"
            key={text}
            component={Link}
            to="/moderator"
            disablePadding
            style={{ textDecoration: "none" }}
          >
            <ListItemButton
              id="sidebarListItem"
              className={`sidebarListItem ${["/moderator"].includes(pathname) ? "active" : ""
                }`}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <Divider />
      <List>
        {["Profile"].map((text, index) => (
          <ListItem
            className="list-nav"
            key={text}
            component={Link}
            to="/moderator/account"
            disablePadding
            style={{ textDecoration: "none" }}
          >
            <ListItemButton
              id="sidebarListItem"
              className={`sidebarListItem ${["/moderator/account"].includes(pathname) ? "active" : ""
                }`}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />

      <List>
        {["Member"].map((text, index) => (
          <ListItem
            className="list-nav"
            disablePadding
            style={{ textDecoration: "none" }}
          >
            <ListItemButton onClick={handleClick}
              id="sidebarListItem"
              className={`sidebarListItem ${["/moderator/member"].includes(pathname) ? "active" : ""
                }`}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

          </ListItem>
        ))}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}
              className="list-nav"
              component={Link}
              to="/moderator/member"
              disablePadding
              style={{ textDecoration: "none" }}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Member details" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}
              className="list-nav"
              component={Link}
              to="/moderator/member-audit"
              disablePadding
              style={{ textDecoration: "none" }}
            >
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="Member audit" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      <Divider />

      <List>
        {["Moderator"].map((text, index) => (
          <ListItem
            className="list-nav"
            disablePadding
            style={{ textDecoration: "none" }}
          >
            <ListItemButton onClick={handleClick2}
              id="sidebarListItem"
              className={`sidebarListItem ${["/moderator/member"].includes(pathname) ? "active" : ""
                }`}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
              {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

          </ListItem>
        ))}
        <Collapse in={open2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {myProfile.isSuperAdmin ? (
              <ListItemButton sx={{ pl: 4 }}
                className="list-nav"
                component={Link}
                to="/moderator/manage-moderator"
                disablePadding
                style={{ textDecoration: "none" }}
              >
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="Manage moderator" />
              </ListItemButton>
            ) : (<></>)}
            <ListItemButton sx={{ pl: 4 }}
              className="list-nav"
              component={Link}
              to="/moderator/moderator-audit"
              disablePadding
              style={{ textDecoration: "none" }}
            >
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="Moderator audit" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      <Divider />






      <List>
        {["Manage Attributes"].map((text, index) => (
          <ListItem
            className="list-nav"
            key={text}
            component={Link}
            to="/moderator/manage-attributes"
            disablePadding
            style={{ textDecoration: "none" }}
          >
            <ListItemButton
              id="sidebarListItem"
              className={`sidebarListItem ${pathname.includes("/moderator/manage-attributes")
                ? "active"
                : ""
                }`}
            >
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List>
        {["Sign Out"].map((text, index) => (
          <ListItem
            id="sidebarListItem"
            className="list-nav"
            key={text}
            onClick={onLogout}
            disablePadding
            style={{ textDecoration: "none" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar style={{ backgroundColor: "#571B38" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { sm: "none" } }}
          >
            Vivaakam
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

SideBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SideBar;
