import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { getRoles } from '../../services/roleService'
import { inviteModerator } from '../../services/userRoleService'
import Input from '../common/input'
import Select from '../common/select'

const schema = yup.object({
  email: yup.string().email().required().label('Email'),
  role: yup.object().required(),
})

const ModeratorInvitation = () => {
  const [roles, setRoles] = useState([])
  const {
    handleSubmit,
    errors,
    register,
    watch,
    setError,
    control,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(schema) })

  useEffect(async () => {
    const { data } = await getRoles()
    setRoles(data)
  }, [])

  const onSubmit = async (data, e) => {
    data.roleId = data.role.id
    delete data.role
    await inviteModerator(data)
    e.target.reset()
  }

  return (
    <form className='form' onSubmit={handleSubmit(onSubmit)}>
      <Input
        name='email'
        label='Email'
        reg={register}
        error={errors.email?.message}
      />
      <Select
        name='role'
        label='Role'
        options={roles}
        control={control}
        error={errors.role?.message}
        setValue={setValue}
        getValues={getValues}
      />
      <button type='submit'>Submit</button>
    </form>
  )
}

export default ModeratorInvitation
