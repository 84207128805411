import * as msal from "@azure/msal-browser";
import { toast } from "react-toastify";

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  authorities: {
    SignIn: {
      authority: process.env.REACT_APP_B2C_SI_AUTHORITY,
    },
  },
  authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN,
};

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.SignIn.authority, // Choose SI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: process.env.REACT_APP_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

class MicrosoftLoginAgent {
  constructor() {
    this.username = "";
    this.account = "";
    this.msalInstance = this.init();
  }

  init() {
    try {
      return new msal.PublicClientApplication(msalConfig);
    } catch (ex) {
      toast.error("Login Failure");
    }
  }

  async login() {
    const request = {
      scopes: process.env.REACT_APP_SCOPES.split(","),
    };

    try {
      const response = await this.msalInstance.loginRedirect(request);
      this.msalInstance.setActiveAccount(response.account);
      return true;
    } catch (ex) {
      return false;
    }
  }

  async logout() {
    try {
      await this.msalInstance.logoutRedirect();
      return true;
    } catch (error) {
      return false;
    }
  }

  async getAccessToken() {
    const request = {
      scopes: process.env.REACT_APP_SCOPES.split(","),
      account: {},
    };

    try {
      const { accessToken } = await this.msalInstance.acquireTokenSilent(
        request
      );
      return accessToken;
    } catch (ex) {
      if (ex.name === "BrowserAuthError") {
        try {
          const response = await this.msalInstance.acquireTokenSilent(request);
          return response.accessToken;
        } catch (ex) {}
      }
    }
  }

  getAccount() {
    return this.msalInstance.getAllAccounts()[0];
  }
}

const microsoftLoginAgent = new MicrosoftLoginAgent();

export { microsoftLoginAgent as MicrosoftLoginAgent };
