import React, { useEffect, useState } from 'react';
import FormControl from "@mui/material/FormControl";
import TextField from '@mui/material/TextField';
import Table from "react-bootstrap/Table";
import { getUser, getUsers } from '../../services/userService';
import Button from "@mui/material/Button";
import { toast } from 'react-toastify';
import { activateModerator, deActivateModerator, inviteModerator } from '../../services/userRoleService';
import { useModerator } from '../../context/moderatorContext';

function ManageModerator() {
  const { myProfile } = useModerator();
  const [users, setUsers] = useState([]);
  const [moderatorEmail, setModeratorEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  useEffect(() => {
    async function fetchData() {
      const users = await getUsers();
      if (users.status === 200 || users.status === 201) {
        setUsers(users.data);
      }
    }
    fetchData();
  }, []);

  const handleSubmit = async () => {
    if (emailRegex.test(moderatorEmail) === false) {
      toast.warn("Please enter a valid email");
      return;
    }
    let data = {
      email: moderatorEmail
    };
    const moderator = await inviteModerator(data);
    if (moderator.status === 200 || moderator.status === 201) {
      toast.success("Moderator added successfully");
      setModeratorEmail("");
    } else {
      toast.error("Unable to add");
    }
    return null;
  };

  const DeActivateModerator = async (id) => {
    if (id) {
      const res = await deActivateModerator(id);
      if (res.status === 204) {
        const users = await getUsers();
        if (users.status === 200 || users.status === 201) {
          setUsers(users.data);
        }
      }
      setIsLoading(true);
      return null;
    }


  };

  const ActivateModerator = async (id) => {
    if (id) {
      const res = await activateModerator(id);
      if (res.status === 200) {
        const users = await getUsers();
        if (users.status === 200 || users.status === 201) {
          setUsers(users.data);
        }
      }
      setIsLoading(true);
      return null;
    }
  };

  return (
    <>
      {
        myProfile?.isSuperAdmin === true ? (
          <div className="container-fluid">
            <div className="row my-5 py-5 px-4">
              <FormControl sx={{ mt: 2 }} fullWidth color="warning">
                <TextField id="standard-basic"
                  placeholder='Enter email'
                  variant="standard"
                  value={moderatorEmail}
                  onChange={(e) => setModeratorEmail(e.target.value)}
                />
              </FormControl>
            </div>
            <Button
              fullWidth
              sx={{ mt: 2, minHeight: 50 }}
              variant="contained"
              color="warning"
              onClick={handleSubmit}
            >
              Add moderator
            </Button>
            <Table responsive striped>
              <thead>
                <tr>
                  <th className="text-center audit-table-heading">
                    Email
                  </th>
                  <th className="text-center audit-table-heading">
                    First name
                  </th>
                  <th className="text-center audit-table-heading">
                    Last name
                  </th>
                  <th className="text-center audit-table-heading">Action</th>
                </tr>
              </thead>
              {users && users.length > 0 && users.map((data, index) => (
                <tr key={index}>
                  <td>{data.email}</td>
                  <td>{data.firstName}</td>
                  <td>{data.lastName}</td>
                  <td>{data.isActive === true ? (
                    <Button
                      fullWidth
                      sx={{ mt: 2, minHeight: 50 }}
                      variant="contained"
                      color="warning"
                      onClick={() => DeActivateModerator(data.id)}
                    >
                      Deactivate
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      sx={{ mt: 2, minHeight: 50 }}
                      variant="contained"
                      color="warning"
                      onClick={() => ActivateModerator(data.id)}
                    >
                      Activate
                    </Button>
                  )
                  }     </td>
                </tr>
              ))}

            </Table>

          </div>) : (<h1>Insufficient permissions</h1>)
      }
    </>
  );
}

export default ManageModerator;