import * as React from "react";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import {
  getMemberAudit,
  getAllMembers,
  getProfileAudits,
  getProfileAudit,
  getMemberAudits,
  getExpressedInterestAudits,
  getExpressedInterestAudit,
  getAllProfiles,
  getMembers,
} from "../../../services/auditService";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ContentLoader from "../../common/contentLoader";

export default function Attribute() {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [auditData, setAuditData] = useState([]);
  const [memberDetails, setMemberDetails] = useState([]);
  const [audit, setAudit] = useState(1);
  const [profileNames, setprofileNames] = useState({});
  const [profileDetails, setprofileDetails] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  let indexOfLastItem = currentPage * itemsPerPage;
  let indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = auditData.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(auditData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (audit === 1) {
        let tempResponse = [];
        let profileArray = [];
        let memberArray = [];
        const response = await getProfileAudits();
        const Profiles = await getAllProfiles();
        const Members = await getMembers();
        if (Profiles.status === 200) {
          profileArray = Profiles.data;
          setprofileDetails(profileArray);
        }
        if (Members.status === 200) {
          memberArray = Members.data;
          setMemberDetails(memberArray);
        }
        tempResponse = response.data;
        setAuditData(tempResponse);
      }
      if (audit === 2) {
        try {
          let dataArray = [];
          let memberArray = [];
          const response = await getMemberAudits();
          const Members = await getAllMembers();
          if (response.status === 200) {
            memberArray = Members.data;
            setMemberDetails(memberArray);
          }
          dataArray = response.data;
          //filtering the records that contains empty new value
          dataArray = dataArray.filter(
            (audit) => audit.changes.split('"')[1] != null
          );
          // soring the fetched values in ascending order according to "audit_id"
          setAuditData(dataArray);
        } catch (error) {
          console.error(error);
        }
      }
      if (audit === 3) {
        try {
          let dataArray = [];
          let profileArray = [];
          let memberArray = [];
          const response = await getExpressedInterestAudits();
          const Profiles = await getAllProfiles();
          const Members = await getMembers();
          if (Profiles.status === 200) {
            profileArray = Profiles.data;
            setprofileDetails(profileArray);
          }
          if (Members.status === 200) {
            memberArray = Members.data;
            setMemberDetails(memberArray);
          }
          dataArray = response.data;
          setAuditData(dataArray);
        } catch (error) {
          console.error(error);
        }
      }
      setLoading(false);
    }
    fetchData();
  }, [audit]);

  function getFirstNameById(id) {
    const user = memberDetails.find((u) => u.id === id);
    return user ? (
      user.firstName
    ) : (
      <span
        style={{
          backgroundColor: "red",
          color: "white",
          padding: "2px 4px",
          borderRadius: "4px",
        }}
      >
        Deleted Profile
      </span>
    );
  }

  function getEmailById(id) {
    const user = memberDetails.find((u) => u.id === id);
    return user ? (
      user.email
    ) : (
      <span
        style={{
          backgroundColor: "red",
          color: "white",
          padding: "2px 4px",
          borderRadius: "4px",
        }}
      >
        Deleted Profile
      </span>
    );
  }

  function getByRequester(id) {
    const profile = profileDetails.find((e) => e.id === id);
    if (
      profile?.status === "HDeleted" ||
      profile?.isHided === true ||
      profile?.isSoftDeleted === true ||
      profile?.status === "Rejected"
    ) {
      const user = memberDetails.find((u) => u.id === profile.externalId);
      return user ? user.firstName : "Unknown";
    }
    return profile ? profile.profileName : "Unknown";
  }
  function getByRequestee(id) {
    const profile = profileDetails.find((e) => e.id === id);
    if (
      profile?.status === "HDeleted" ||
      profile?.isHided === true ||
      profile?.isSoftDeleted === true ||
      profile?.status === "Rejected"
    ) {
      const user = memberDetails.find((u) => u.id === profile.externalId);
      return user ? user.firstName : "Unknown";
    }
    return profile ? profile.profileName : "Unknown";
  }
  function getByProfileId(id) {
    const profile = profileDetails.find((e) => e.id === id);
    if (
      profile?.status === "HDeleted" ||
      profile?.isHided === true ||
      profile?.isSoftDeleted === true ||
      profile?.status === "Rejected"
    ) {
      const user = memberDetails.find((u) => u.id === profile.externalId);
      return user ? user.firstName : "Unknown";
    }
    return profile ? profile.profileName : "Unknown";
  }
  const AuditChange = async (event) => {
    setAuditData([]);
    setFromDate(null);
    setToDate(null);
    setAudit(event.target.value);
  };
  const handlesearch = async () => {
    try {
      setLoading(true);
      let tempResponse = [];
      if (!fromDate || !toDate) {
        return;
      }

      if (audit === 1) {
        const response = await getProfileAudit(fromDate, toDate);
        tempResponse = response.data;
        setAuditData(tempResponse);
      } else if (audit === 2) {
        const response = await getMemberAudit(fromDate, toDate);
        tempResponse = response.data;
        //filtering the records that contains empty new value
        tempResponse = tempResponse.filter(
          (audit) => audit.changes.split('"')[1] != null
        );
        // soring the fetched values in ascending order according to "audit_id"
        setAuditData([...tempResponse].sort((a, b) => b.id - a.id));
      } else if (audit === 3) {
        const response = await getExpressedInterestAudit(fromDate, toDate);
        tempResponse = response.data;
        setAuditData(tempResponse);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const MAX_PAGE_NUMBERS = 5;

  // Define the total number of pages
  const totalPageCount = Math.ceil(auditData.length / itemsPerPage);

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to handle next page
  const handleNextPage = () => {
    if (currentPage < totalPageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row my-5 py-5 px-4">
        <div className="col-lg-2 col-sm-12">
          <FormControl sx={{ mt: 2 }} fullWidth color="warning">
            <InputLabel id="demo-simple-select-helper-label">
              Select Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={audit}
              label="Select Type"
              onChange={AuditChange}
            >
              <MenuItem value={1}>Profile Audit</MenuItem>
              <MenuItem value={2}>Member Audit</MenuItem>
              <MenuItem value={3}>Expressed Interest Audit</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/*... DATE_PICKER fields & search button ...*/}
        <div className="col-lg-5 col-sm-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              sx={{ mt: 1 }}
              components={["DatePicker", "DatePicker"]}
            >
              <DatePicker
                label="From Date"
                color="warning"
                value={fromDate}
                onChange={(date) => setFromDate(date)}
              />
              <DatePicker
                label="To Date"
                color="warning"
                value={toDate}
                onChange={(date) => setToDate(date)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className="col-lg-2 col-sm-12">
          <Button
            fullWidth
            sx={{ mt: 2, minHeight: 50 }}
            variant="contained"
            color="warning"
            onClick={handlesearch}
          >
            Search
          </Button>
        </div>
      </div>
      {/* Render the loading component if loading is true */}
      {loading ? (
        <ContentLoader />
      ) : (
        <div className="col-md-12">
          <Table responsive striped>
            <thead>
              {audit === 1 && (
                <tr>
                  <th className="text-center audit-table-heading">
                    Profile name/First name
                  </th>
                  <th className="text-center audit-table-heading">
                    Changed By
                  </th>
                  <th className="text-center audit-table-heading">
                    Previous Value
                  </th>
                  <th className="text-center audit-table-heading">New value</th>
                  <th className="text-center audit-table-heading">Action</th>
                  <th className="text-center audit-table-heading">Date</th>
                </tr>
              )}
              {audit === 2 && (
                <tr>
                  <th className="text-center audit-table-heading">
                    Member Name
                  </th>
                  <th className="text-center audit-table-heading">Email</th>
                  <th className="text-center audit-table-heading">
                    Change Type
                  </th>
                  <th className="text-center audit-table-heading">New</th>
                  <th className="text-center audit-table-heading">Old</th>
                </tr>
              )}
              {audit === 3 && (
                <tr>
                  <th className="text-center audit-table-heading">Requester</th>
                  <th className="text-center audit-table-heading">Requestee</th>
                  <th className="text-center audit-table-heading">
                    Previous Value
                  </th>
                  <th className="text-center audit-table-heading">New value</th>
                  <th className="text-center audit-table-heading">Date</th>
                </tr>
              )}
            </thead>
            <tbody>
              {audit === 2 &&
                auditData.length > 0 && //if the array of fetched datas is not empty , then map the datas in to the table
                auditData
                  .slice(
                    currentPage * itemsPerPage - itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((auditData, index) => (
                    <tr key={auditData.id}>
                      <td className="text-center audit-table-col">
                        {getFirstNameById(auditData.memberId)}
                      </td>
                      <td className="text-center audit-table-col">
                        {getEmailById(auditData.memberId)}
                      </td>
                      {/* ...splitting the "changes" string into field, old value & new value ...*/}
                      <td className="text-center audit-table-col">
                        {auditData.changes.split(":")[0]}
                      </td>
                      <td className="text-center audit-table-col">
                        {auditData.changes.split('"')[3]}
                      </td>
                      <td className="text-center audit-table-col">
                        {auditData.changes.split('"')[1]}
                      </td>
                    </tr>
                  ))}
              {audit === 1 &&
                auditData
                  .sort((a, b) => new Date(b.time) - new Date(a.time))
                  .slice(
                    currentPage * itemsPerPage - itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((audit, index) => {
                    return (
                      <tr key={audit.id}>
                        <td className="text-center audit-table-col">
                          {getByProfileId(audit.profileId)}
                        </td>
                        {audit.userId == null ? (
                          <>
                            <td className="text-center audit-table-col">
                              User
                            </td>
                            <td className="text-center audit-table-col">
                              {audit.action === "Profile holded" ||
                                audit.action === "Profile deleted" ||
                                audit.action === "Approved profile" ||
                                audit.action === "Rejected profile" ||
                                audit.action === "Profile unholded"
                                ? "-"
                                : audit.changes.split("->")[0]}
                            </td>
                            <td className="text-center audit-table-col">
                              {audit.action === "Profile holded" ||
                                audit.action === "Profile deleted" ||
                                audit.action === "Approved profile" ||
                                audit.action === "Rejected profile" ||
                                audit.action === "Profile unholded"
                                ? "-"
                                : audit.changes.split("->")[1]}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="text-center audit-table-col">
                              Moderator
                            </td>
                            <td className="text-center audit-table-col">
                              {audit.action === "Profile holded" ||
                                audit.action === "Profile deleted" ||
                                audit.action === "Approved profile" ||
                                audit.action === "Rejected profile" ||
                                audit.action === "Profile unholded"
                                ? "-"
                                : audit.changes.split("->")[0]}
                            </td>
                            <td className="text-center audit-table-col">
                              {audit.action === "Profile holded" ||
                                audit.action === "Profile deleted" ||
                                audit.action === "Approved profile" ||
                                audit.action === "Rejected profile" ||
                                audit.action === "Profile unholded"
                                ? "-"
                                : audit.changes.split("->")[1]}
                            </td>
                          </>
                        )}
                        <td className="text-center audit-table-col">
                          <span
                            style={{ padding: "10px 8px" }}
                            className="badge badge-success"
                          >
                            {audit.action}
                          </span>
                        </td>
                        <td className="text-center audit-table-col">
                          {new Date(audit.time).toLocaleDateString("en-US")}
                        </td>
                      </tr>
                    );
                  })}
              {audit === 3 &&
                auditData.length > 0 &&
                auditData
                  .sort((a, b) => new Date(b.time) - new Date(a.time))
                  .slice(
                    currentPage * itemsPerPage - itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((data, index) => (
                    <tr key={data.id}>
                      <td className="text-center audit-table-col">
                        {getByRequester(data.requesterProfileId)}
                      </td>
                      <td className="text-center audit-table-col">
                        {getByRequestee(data.requesteeProfileId)}
                      </td>
                      <td className="text-center audit-table-col">
                        {data.action === "Profile holded" ||
                          data.action === "Profile deleted" ||
                          data.action === "Approved profile" ||
                          data.action === "Rejected profile" ||
                          data.action === "Profile unholded"
                          ? "-"
                          : data.changes.split("->")[0]}
                      </td>
                      <td className="text-center audit-table-col">
                        {data.action === "Profile holded" ||
                          data.action === "Profile deleted" ||
                          data.action === "Approved profile" ||
                          data.action === "Rejected profile" ||
                          data.action === "Profile unholded"
                          ? "-"
                          : data.changes.split("->")[1]}
                      </td>
                      <td className="text-center audit-table-col">
                        {new Date(data.time).toLocaleDateString("en-US")}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>

          <div className="row">
            <Pagination>
              <Pagination.Prev
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              />
              {pageNumbers.map((num) => {
                if (
                  num === 1 || // Always show the first page
                  num === totalPageCount || // Always show the last page
                  (num >= currentPage - MAX_PAGE_NUMBERS &&
                    num <= currentPage + MAX_PAGE_NUMBERS)
                ) {
                  return (
                    <Pagination.Item
                      key={num}
                      active={num === currentPage}
                      onClick={() => handlePageChange(num)}
                    >
                      {num}
                    </Pagination.Item>
                  );
                } else if (
                  num === currentPage - MAX_PAGE_NUMBERS - 1 ||
                  num === currentPage + MAX_PAGE_NUMBERS + 1
                ) {
                  // Show an ellipsis (...) before and after the hidden page numbers
                  return <Pagination.Ellipsis key={num} disabled />;
                }
                return null; // Hide other page numbers
              })}
              <Pagination.Next
                onClick={handleNextPage}
                disabled={currentPage === totalPageCount}
              />
            </Pagination>
          </div>
        </div>
      )}
    </div>
  );
}
