import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useModerator } from "../../../context/moderatorContext";
import {
  deleteProfile,
  getProfile,
  getSoftDeletedProfiles,
  getMemberDetailsById
} from "../../../services/profileService";
import FullProfileDetails from "../profileRequest/fullProfileDetails";
import "./deletedProfiles.css";
import ContentLoader from './../../common/contentLoader';
import { toast } from 'react-toastify';

const DeletedProfile = (props) => {
  const { myProfile } = useModerator();
  const { setDeletedProfiles } = useModerator();
  const [deletedProfile, setDeletedProfile] = useState();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [member, setMember] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    async function fetcData() {
      setLoading(true);
      const { data, status } = await getProfile(props.location.state.profile.id);
      if (status === 200) {
        const member = await getMemberDetailsById(data.externalId);
        setMember(member.data);
        setDeletedProfile(data);
        setLoading(false);
      } else {
        setMember([]);
        setDeletedProfile([]);
        setLoading(false);
      }
    }
    fetcData();
  }, []);

  const handleDelete = async (id) => {
    const response = await deleteProfile(id, myProfile.id);
    if (response.status == 201) {
      toast.success("This Profile deleted Successfully!!!");
      var today = new Date();
      today.setDate(today.getDate() - 180);
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      let obj = { toDate: date };
      const deletedProfilesResponse = await getSoftDeletedProfiles(obj);
      if ((deletedProfilesResponse.status = 200)) {
        setDeletedProfiles(deletedProfilesResponse.data);
      }
      // handleShow();
      history.replace(`/moderator/deleted-profiles`);
    } else {
      toast.error("Unable to delete this profile");
    }
  };

  return (
    <>
      {loading && <ContentLoader className="spinner" />}
      {!loading &&
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Matrimony</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to delete this Profile?</Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  handleDelete(deletedProfile.id);
                }}
              >
                Yes
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleClose();
                }}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
          {deletedProfile && (
            <div className="home">
              <div className="col-md-10">
                <div className="card m-2">
                  <FullProfileDetails profile={deletedProfile} {...props} member={member} />
                  <button
                    className="btn btn-primary"
                    onClick={() => handleShow()}
                  >
                    Delete
                  </button>
                  <br />
                </div>
              </div>
            </div>
          )}
        </>
      }
    </>
  );
};

export default DeletedProfile;
