import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  approveUserRoleRequest,
  getUserRoleRequests,
  revokeUserRoleRequest,
} from '../../services/userRoleService'

const UserRoleRequests = () => {
  const [userRoleRequests, setUserRoleRequests] = useState([])
  const history = useHistory()

  useEffect(async () => {
    const { data } = await getUserRoleRequests()
    setUserRoleRequests(data)
  }, [])

  const handleApprove = async (id) => {
    await approveUserRoleRequest(id)
    history.replace('/admin/user-role-requests')
    window.location.reload(false)
  }

  const handleReject = async (id) => {
    await revokeUserRoleRequest(id)
    history.replace('/admin/user-role-requests')
    window.location.reload(false)
  }

  return (
    <div className='row'>
      <div className='col-md-2'></div>
      <table className='col-md-10'>
        <div className='table'>
          <tr>
            <th scope='col'>ID</th>
            <th scope='col'>Email</th>
            <th scope='col'>Role</th>
          </tr>
          {userRoleRequests &&
            userRoleRequests.map((userRoleRequest) => {
              return (
                <tr>
                  <td>
                    <label>{userRoleRequest.id}</label>
                  </td>
                  <td>
                    <label>{userRoleRequest.user.email}</label>
                  </td>
                  <td>
                    <label>{userRoleRequest.role.name}</label>
                  </td>
                  <td>
                    <button
                      type='submit'
                      onClick={() => handleApprove(userRoleRequest.id)}
                    >
                      Approve
                    </button>
                  </td>
                  <td>
                    <button
                      type='submit'
                      onClick={() => handleReject(userRoleRequest.id)}
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              )
            })}
        </div>
      </table>
    </div>
  )
}

export default UserRoleRequests
