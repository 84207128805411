import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import ContentLoader from "../common/contentLoader";
import { getActiveProfilesCount } from "../../services/profileService";
import { getMember } from "../../services/auditService";

export default function ActiveProfile() {
  const [activeProfilesData, setActiveProfilesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const MAX_PAGE_NUMBERS = 5;

  const fetchDataAndMemberDetails = async () => {
    try {
      setLoading(true);

      const activeProfilesResponse = await getActiveProfilesCount();
      const activeProfiles = activeProfilesResponse.data;

      const profilesWithDetails = await Promise.all(
        activeProfiles.map(async (profile) => {
          const memberDetails = await getMember(profile.externalId);
          return {
            ...profile,
            email: memberDetails.data.email,
            phoneNumber: memberDetails.data.phoneNumber,
          };
        })
      );

      setActiveProfilesData(sortProfilesByIdDescending(profilesWithDetails));
      setLoading(false);
      setDataLoaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sortProfilesByIdDescending = (profiles) => {
    const sortedProfiles = [...profiles];
    sortedProfiles.sort((a, b) => b.id - a.id);
    return sortedProfiles;
  };

  useEffect(() => {
    fetchDataAndMemberDetails();
  }, []);

  useEffect(() => {
    if (dataLoaded) {
      setActiveProfilesData(sortProfilesByIdDescending(activeProfilesData));
    }
  }, [activeProfilesData, dataLoaded]);

  const totalPageCount = Math.ceil(activeProfilesData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProfiles = activeProfilesData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const pageNumbers = [];
  for (let i = 1; i <= totalPageCount; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="container-fluid mt-5 px-5">
      <div className="row mt-5 pt-5">
        <h4 className="table-title mb-4">Active Profiles</h4>
      </div>
      {loading ? (
        <ContentLoader />
      ) : (
        <div className="row mb-5 pb-5">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-center table-heading">User ID</th>
                <th className="text-center table-heading">User Name</th>
                <th className="text-center table-heading">First Name</th>
                <th className="text-center table-heading">Last Name</th>
                <th className="text-center table-heading">Email</th>
                <th className="text-center table-heading">Contact No</th>
              </tr>
            </thead>
            <tbody>
              {currentProfiles.map((profile) => (
                <tr key={profile.id}>
                  <td className="text-center table-col">{profile.id}</td>
                  <td className="text-center table-col">
                    {profile.profileName}
                  </td>
                  <td className="text-center table-col">{profile.firstName}</td>
                  <td className="text-center table-col">{profile.surname}</td>
                  <td className="text-center table-col">{profile.email}</td>
                  <td className="text-center table-col">
                    {profile.phoneNumber}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="row">
            <Pagination>
              <Pagination.Prev
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              />
              {pageNumbers.map((num) => {
                if (
                  num === 1 || // Always show the first page
                  num === totalPageCount || // Always show the last page
                  (num >= currentPage - MAX_PAGE_NUMBERS &&
                    num <= currentPage + MAX_PAGE_NUMBERS)
                ) {
                  return (
                    <Pagination.Item
                      key={num}
                      active={num === currentPage}
                      onClick={() => handlePageChange(num)}
                    >
                      {num}
                    </Pagination.Item>
                  );
                } else if (
                  num === currentPage - MAX_PAGE_NUMBERS - 1 ||
                  num === currentPage + MAX_PAGE_NUMBERS + 1
                ) {
                  // Show an ellipsis (...) before and after the hidden page numbers
                  return <Pagination.Ellipsis key={num} disabled />;
                }
                return null; // Hide other page numbers
              })}
              <Pagination.Next
                onClick={handleNextPage}
                disabled={currentPage === totalPageCount}
              />
            </Pagination>
          </div>
        </div>
      )}
    </div>
  );
}
