import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useModerator } from "../../../context/moderatorContext";
import { getMe, verifyUpdatedEmail } from "../../../services/userService";

const UpdatedEmailVerification = () => {
  const { setMyProfile } = useModerator();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const userId = searchParam.get("user-id");
  const token = searchParam.get("token");
  const [show, setShow] = useState(false);
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(async () => {
    // const myProfilesResponse = await getMe("me");
    // if ((myProfilesResponse.status = 200)) {
      // if (myProfilesResponse.data.id == userId) {
        const verificationResponse = await verifyUpdatedEmail(userId, token);
        if (verificationResponse.status == 201) {
          const myProfilesResponseAfterUpdate = await getMe("me");
          if ((myProfilesResponseAfterUpdate.status = 200)) {
            setMyProfile(myProfilesResponseAfterUpdate.data);
            setShow(true);
          }
        // }
      // }
    }
  }, []);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Matrimony</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>Your New Email Updated Successfully !!!!</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              history.replace(`/moderator/account`);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdatedEmailVerification;
