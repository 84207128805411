import React, { useEffect, useState } from 'react'
import {
  getActiveUsers,
  revokeUserRoleRequest,
} from '../../services/userRoleService'

const UserRoleRevocation = () => {
  const [activeUsers, setActiveUsers] = useState()

  useEffect(async () => {
    const { data } = await getActiveUsers()
    setActiveUsers(data)
  }, [])

  const handleRevoke = async (id) => {
    await revokeUserRoleRequest(id)
    window.location.reload(false)
  }

  return (
    <div className='row'>
      <div className='col-md-2'></div>
      <table className='col-md-10'>
        <div className='table'>
          <tr>
            <th scope='col'>ID</th>
            <th scope='col'>Email</th>
            <th scope='col'>Role</th>
          </tr>
          {activeUsers &&
            activeUsers.map((activeUser) => {
              return (
                <tr>
                  <td>
                    <label>{activeUser.id}</label>
                  </td>

                  <td>
                    <label>{activeUser.user.email}</label>
                  </td>

                  <td>
                    <label>{activeUser.role.name}</label>
                  </td>
                  <td>
                    <button
                      type='submit'
                      onClick={() => handleRevoke(activeUser.id)}
                    >
                      Revoke
                    </button>
                  </td>
                </tr>
              )
            })}
        </div>
      </table>
    </div>
  )
}

export default UserRoleRevocation
