import { yupResolver } from "@hookform/resolvers/yup";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useModerator } from "../../../context/moderatorContext";
import { getSoftDeletedProfiles } from "../../../services/profileService";
import Input from "../../common/input";
import "./deletedProfiles.css";

const schema = yup.object({
  // fromDate: yup.string().required(),
  // .test("DoB", "Age must be above 18", (value) => {
  //   return moment().diff(moment(value), "years") >= 18;
  // }),
  toDate: yup.string().required(),
  // .test("DoB", "Age must be above 18", (value) => {
  //   return moment().diff(moment(value), "years") >= 18;
  // }),
});

const DeletedProfiles = () => {
  const [show, setShow] = useState(false);
  const { deletedProfiles, setDeletedProfiles } = useModerator();
  const [formatDeletedProfiles, setFormatDeletedProfiles] = useState([]);

  const {
    handleSubmit,
    errors,
    register,
    watch,
    setError,
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [timezoneOffset, setTimezoneOffset] = useState(null);
  const [utcDate, setUtcDate] = useState(null);

  //getting the time zone difference 
  useEffect(() => {
    const offsetInMinutes = new Date().getTimezoneOffset();
    setTimezoneOffset(offsetInMinutes);
  }, []);

  //converting UTC time to local time
  function convertToLocalTime(utcDate) {

    const UtcDate = new Date(utcDate);
    const localDate = new Date(UtcDate.getTime() - (timezoneOffset * 60000));
    const localTimeString = localDate.toLocaleString();
    return localTimeString;
  }
  useEffect(() => {
    async function handleData() {
      setFormatDeletedProfiles(formatData(deletedProfiles));
    }
    handleData();
  }, [deletedProfiles, timezoneOffset]);

  const formatData = (data) => {
    let newData = [];
    data &&
      data.map((field) => {
        let obj = {};
        obj.id = field.profile.id;
        obj.profileName = field.profile.profileName;
        obj.firstName = field.profile.firstName;
        obj.surname = field.profile.surname;
        obj.deletedAt = convertToLocalTime(field.deletedAt);
        obj.reason = field.reason;
        newData.push(obj);
      });
    return newData;
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "profileName",
      headerName: "Profile Name",
      width: 200,
      renderCell: (params) => {
        return <div className="container userListUser">{params.row.profileName}</div>;
      },
    },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "surname", headerName: "Surname", width: 200 },
    { field: "deletedAt", headerName: "Deleted At", width: 200 },
    { field: "reason", headerName: "Reason", width: 200 },
    {
      field: "review",
      headerName: "Review",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{
                pathname: `/moderator/deleted-profile/${params.row.id}`,
                state: { profile: params.row },
              }}
            >
              <button className="userListEdit">Review</button>
            </Link>
          </>
        );
      },
    },
  ];

  const onSubmit = async (dates) => {
    const { data, status } = await getSoftDeletedProfiles(dates);
    if (status === 200) {
      setFormatDeletedProfiles(formatData(data));
    } else {
      setFormatDeletedProfiles([]);
    }
  };

  return (
    <>
      <div className="home py-5 px-4 mt-5">
        <h4 className="table-title">Deleted Profiles</h4>
        <table className="col-md-10">
          <div className="datePicker-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col">
                  <Input
                    type="date"
                    placeholder="dd-mm-yyyy"
                    error={errors.fromDate?.message}
                    reg={register}
                    name="fromDate"
                    label="From Date"
                  />
                </div>
                <div className="col">
                  <Input
                    type="date"
                    placeholder="dd-mm-yyyy"
                    error={errors.toDate?.message}
                    reg={register}
                    name="toDate"
                    label="To Date"
                  />
                </div>
                <div className="col ">
                  <button className="btn btn-primary btn-deletedProfiles">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </table>

        <div className="userList">
          <DataGrid
            rows={formatDeletedProfiles}
            disableSelectionOnClick
            columns={columns}
            pageSize={8}
          // checkboxSelection
          />
        </div>
      </div>
    </>
  );
};

export default DeletedProfiles;
