// import { DataGrid } from "@material-ui/data-grid";
import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import {
  AttributesProvider,
  useAttributes,
} from "../../../context/attributeContext";
import { toast } from "react-toastify";
import {
  approvePhoto,
  getNewPhotos,
  rejectPhoto,
} from "../../../services/photoService";
import { getProfile } from "../../../services/profileService";
import ContentLoader from "../../common/contentLoader";
import "./manageAttributes.css";
// import LocationDetails from "../profileRequest/locationDetails";
// import { useForkRef } from "@material-ui/core";
// import { ArtTrackSharp } from "@material-ui/icons";

const ManageAttributes = () => {
  const {
    atts,
    attsLoading,
    attStatus,
    attStatusLoading,
    SearchAtts,
    UpdateAtt,
    AddAtt,
  } = useAttributes();
  const [selectedGroup, setSelectedGroup] = useState("Basic Details");
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [showAttbShowMsg,setshowAttbShowMsg]=useState(true);
  const [previousSearch, setPreviousSearch] = useState({
    attribute: null,
    status: null,
  });

  const sampleData = {
    "Basic Details": ["Creators", "Languages", "MaritalStatus"],
    "Location & Religious...": [
      "ResidentialStatus",
      "Castes",
      "EthnicOrigins",
      "MotherTongues",
      "Religions",
      "Communities",
    ],
    "Education & Profession": [
      "JobStatus",
      "JobSectors",
      "Professions",
      "Qualifications",
    ],
    "Hobbies & Interests": [
      "Hobbies",
      "Interests",
      "Cuisines",
      "Movies",
      "Music",
      "DietHabbit",
      "DressStyles",
      "DrinkingHabbit",
      "SmokingHabbit",
      "Sports",
      //"ChildrenCriteria",
    ],
  };

  function handleGroupUpdate(e) {
    setSelectedGroup(e.target.value);
  }

  /*
    OnClick Event Handler for Attribute Text and Dropdowns.
  */
  function enableButton(e) {
    //get Id of attribute (using regex to match after "-")
    var id = String(e.target.id).match(/[^-]*$/)[0];

    //Enable button relevant to attribute
    document.getElementById("attBtn-" + id).removeAttribute("disabled");
    document.getElementById("attBtn-" + id).setAttribute("onClick", handleSave);
  }

  /*
    Search for Attributes by Dropdown Filters
  */
  function handleSearch() {
    setSearchDisabled(true);

    //Disable button to avoid user spam
    setTimeout(() => {
      setSearchDisabled(false);
    }, 750); //700ms

    var searchAttribute = document.getElementById("attributeFilter").value;
    var searchStatus = document.getElementById("statusFilter").value;

    setPreviousSearch({ attribute: searchAttribute, status: searchStatus });

    //call get endpoint to get queried data in form '/<attribute>?status=<status>' (e.g. /creators?status=1)
    SearchAtts(searchAttribute, searchStatus);
  }

  /*
  
    Updating / Adding a new attribute
  */
  async function handleSave(e) {
    //get Id of attribute (using regex to match after "-")
    var id = String(e.target.id).match(/[^-]*$/)[0];
    //Check if attribuite already exists

    if (id !== null && atts.some((att) => att.id == id)) {
      //Set button text to saving... && disabled

      var attName = document.getElementById("attName-" + id).value;
      var attStatus = document.getElementById("attStatus-" + id).value;
      attName = String(attName).trim();

      if (attName == "") return toast.error("Enter a valid Attribute Name");

      // Check if the attribute name already exists
    const existingAtt = atts;
    let isAttributeExists = false;
    existingAtt.forEach((data) => {
      if (attName.toLowerCase() === data.name.toLowerCase() && attStatus == data.status)  {
        isAttributeExists = true;
      }
    });
    if (isAttributeExists) {
      return toast.error("Attribute name already exists");
    }

      var body = { name: attName, status: attStatus };
      await UpdateAtt(previousSearch.attribute, id, body);

      //Refresh displayed data
      await SearchAtts(previousSearch.attribute, previousSearch.status);
      return;
    }
    toast.error("Unexpected Error occured. Try again later.");
  }

  async function handleAdd(e) {
    //Set button text to saving... && disabled

    var attName = document.getElementById("attName-new").value;
    var attStatus = document.getElementById("attStatus-new").value;
    attName = String(attName).trim();

    if (attName == "") return toast.error("Enter a valid Attribute Name");

    // Check if the attribute name already exists
    const existingAtt = atts;
    let isAttributeExists = false;
    existingAtt.forEach((data) => {
      if (attName.toLowerCase() === data.name.toLowerCase() && attStatus == data.status)  {
        isAttributeExists = true;
      }
    });
    if(!showAttbShowMsg) return;
    if (isAttributeExists) {
      setshowAttbShowMsg(false)
      return toast.error("Attribute name already exists");
    }

    var body = { name: attName, status: attStatus };
    await AddAtt(previousSearch.attribute, body);
    //Refresh displayed data
    await SearchAtts(previousSearch.attribute, previousSearch.status);

    //Clear the fields
    document.getElementById("attName-new").value = "*Enter*";
    setshowAttbShowMsg(true);
    return;
  }

  return (
    <>
      {attStatusLoading && <ContentLoader />}
      {!attStatusLoading && (
        <div>
          {/* Form Section*/}
          <div className="container py-5 px-4 mt-5">
            <div class="col-xs-12">
              <h4>Attribute Management</h4>
              <br></br>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div class="row">
                <div className="form-group">
                  <label>Section</label>
                  <br></br>
                  <select
                    class="form-control"
                    aria-label="Default select example"
                    value={selectedGroup /* Default */}
                    onChange={handleGroupUpdate}
                  >
                    {Object.keys(sampleData).map((group) => (
                      <option value={group}>{group}</option>
                    ))}
                  </select>
                </div>
                &nbsp; &nbsp; &nbsp;
                <div className="form-group">
                  <label>Attribute Category</label>
                  <br></br>
                  <select
                    id="attributeFilter"
                    class="form-control"
                    aria-label="Default select example"
                  >
                    {sampleData[selectedGroup] &&
                      sampleData[selectedGroup].map((attribute) => (
                        <option value={attribute}>{attribute}</option>
                      ))}
                  </select>
                </div>
                &nbsp; &nbsp; &nbsp;
                {/* Statuses Dropdown and Search Button */}
                <div className="form-group">
                  <label>Status</label>
                  <br></br>
                  <select
                    id="statusFilter"
                    class="form-control"
                    aria-label="Default select example"
                  >
                    {attStatus.map((status) => (
                      <option value={status}>{status}</option>
                    ))}
                  </select>
                </div>
                &nbsp; &nbsp; &nbsp;
                <div className="form-group">
                  <label>&nbsp;</label>
                  <Button
                    bsStyle="primary"
                    bsSize="large"
                    onClick={handleSearch}
                    disabled={searchDisabled}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>

            {/* Attribute Table Section*/}
            <>
              {!attsLoading && (
                <div class="col-xs-10">
                  <br></br>
                  <br></br>
                  <table class="table ml-2">
                    <thead>
                      <tr>
                        <th scope="col">Attribute</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {atts.map((result) => (
                        <tr>
                          <td>
                            <input
                              id={"attName-" + result.id}
                              type="text"
                              defaultValue={result.name}
                              class="form-control"
                            />
                          </td>
                          <td>
                            <select
                              id={"attStatus-" + result.id}
                              class="form-control"
                              aria-label="Select Attribute Status"
                              defaultValue={result.status}
                            >
                              {attStatus.map((status) => (
                                <option value={status}>{status}</option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <Button
                              id={"attBtn-" + result.id}
                              className="buttonW"
                              bsStyle="primary"
                              bsSize="sm"
                              onClick={handleSave}
                              disabled={false}
                            >
                              Save
                            </Button>
                          </td>
                        </tr>
                      ))}

                      {/* New Attribute Section */}

                      <tr>
                        <td>
                          <input
                            id="attName-new"
                            type="text"
                            class="form-control"
                            defaultValue={"*Enter*"}
                            onChange={enableButton}
                          />
                        </td>
                        <td>
                          <select
                            id="attStatus-new"
                            class="form-control"
                            aria-label="Select Attribute Status"
                            onChange={enableButton}
                          >
                            {attStatus.map((status) => (
                              <option value={status}>{status}</option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <Button
                            id="attBtn-new"
                            className="buttonW"
                            variant="success"
                            bsSize="sm"
                            onClick={handleAdd}
                          >
                            Add
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageAttributes;
