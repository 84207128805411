import React, { useContext, useState, useEffect } from "react";
import { MicrosoftLoginAgent } from "../services/auth/msal";
import { getMe } from "../services/userService";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [account, setAccount] = useState(MicrosoftLoginAgent.getAccount());
  const [isModerator, setIsModerator] = useState(false);
  const [isLoadingModerator, setIsLoadingModerator] = useState(true);

  useEffect(async () => {
    async function fetchData(params) {
      setIsLoadingModerator(true);
      if (account) {
        const response = await getMe("me");
        if (response.status === 200) {
          setIsModerator(true);
        } else {
          setIsModerator(false);
        }
      }
      setIsLoadingModerator(false);
    }

    fetchData();
  }, [account]);

  return (
    <AuthContext.Provider
      value={{
        account,
        setAccount,
        isModerator,
        setIsModerator,
        isLoadingModerator,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
