import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getSearchedProfiles,
  getActiveProfilesCount,
} from "../../../../services/profileService";
import "./profileSearch.css";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ProfileSearch = () => {
  const [show, setShow] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchPrev, setSearchPrev] = useState("");
  const [searchedProfiles, setSearchedProfiles] = useState([]);
  const [activeProfilesCount, setActiveProfilesCount] = useState(0);

  const history = useHistory();

  let flag = false;
  const clicked = new Date().getTime();

  const handleChange = async (e) => {
    const clickedTwice = new Date().getTime();
    //time taken between 2 consecutive clicks on search button
    const waiting = clickedTwice - clicked;
    //checking whether the search button is clicked twice without changing the search value.
    if (searchPrev === searchValue) {
      flag = true;
      //enable the toast message after given time
      if (waiting > 5700) {
        flag = false;
      }
    } else {
      flag = false;
    }
    if (searchValue != 0) {
      e.preventDefault();
    }
    const response = await getSearchedProfiles(searchValue.toLowerCase());
    if (response.data.length == 0 && searchValue != 0 && !flag) {
      toast.info("Profile Not found");
    }
    setSearchPrev(searchValue);
    setSearchedProfiles(response.data);
  };

  const fetchProfilesData = async () => {
    const [activeProfilesResponse] = await Promise.all([
      getActiveProfilesCount(),
    ]);

    setActiveProfilesCount(activeProfilesResponse.data.length);
  };
  useEffect(() => {
    fetchProfilesData();
    if (searchedProfiles.length > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [searchedProfiles]);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "profileName",
      headerName: "Profile Name",
      width: 200,
      // renderCell: (params) => {
      //   return (
      //     <div className="userListUser">
      //       <img
      //         className="userListImg"
      //         src={`data:image;base64,${params.row.fileContents}`}
      //         alt=""
      //       />
      //       {params.row.profileName}
      //     </div>
      //   );
      // },
    },
    { field: "firstName", headerName: "First Name", width: 180 },
    { field: "surname", headerName: "Surname", width: 180 },
    { field: "status", headerName: "Status", width: 180 },
    {
      field: "view",
      headerName: "View",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{
                pathname: `/moderator/profile/${params.row.id}`,
                state: { profile: params.row },
              }}
            >
              <button className="userListEdit">View</button>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className="container-fluid">
      <div className="mt-5" >
        <div className="topnav" >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid style={{ display: 'flex', alignItems: 'center'}} item lg={6} md={6} sm={12} xs={12}>
              {activeProfilesCount > 0 && (
                <>
                    <p2>
                      Active account count {activeProfilesCount} &emsp;
                    </p2>
                  <div className="profbtnrow">
                    <Button
                      className="profbtn"
                      component={Link}
                      onClick={() => {
                        history.push({
                          pathname: `/moderator/active-profile`,
                        });
                      }}
                      variant="contained"
                    >
                      View
                    </Button>
                  </div>
                </>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="search-container">
                <form>
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search Profile"
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchValue(e.target.value);
                    }}
                  />
                  <button
                    className="btn-search"
                    type="submit"
                    onClick={handleChange}
                  >
                    Search
                  </button>
                </form>
              </div>
            </Grid>
          </Grid>
          </Box>
        </div>
      </div>
      <div className="row mt-5 pt-4">
        <div className={`${show ? "ps-userList-before" : "ps-userList-after"}`}>
          {show && (
            <div className="row" style={{ height: 300, width: "100%" }}>
              <DataGrid
                rows={searchedProfiles}
                disableSelectionOnClick
                columns={columns}
                pageSize={8}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileSearch;
