import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import './profileDetails.css';
import PersonIcon from '@mui/icons-material/Person';

const AboutDetails = (props) => {
  return (
    <>
      <div className="topic"><PersonIcon />&nbsp; About Me</div>
      <div className="content">About:
        <div>
          <span>{props.profile?.about}</span>
        </div>
      </div>
    </>
  );
};

export default AboutDetails;
