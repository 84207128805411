import http from "./httpService";

export function getProfile(id) {
  return http.get(`/profiles/${id}`);
}

export function getProfiles() {
  return http.get(`/profiles`);
}

export function getRequestedProfiles() {
  return http.get(`/requested-profiles`);
}

export function getHoldedProfiles() {
  return http.get(`/holded-profiles`);
}

export function getAllProfilePhotos(id) {
  return http.get(`/profiles/${id}/photos`);
}

export function holdProfile(id, moderatorId) {
  const profile = {
    profileId: id,
    moderatorId: moderatorId,
  };
  return http.post(`/holded-profiles`, profile);
}

export function unholdProfile(id, moderatorId) {
  const profile = {
    profileId: id,
    moderatorId: moderatorId,
  };
  return http.delete(`/holded-profiles/${id}/${moderatorId}`);
}

export function getSoftDeletedProfiles(dates) {
  if (dates.fromDate) {
    return http.get(
      `/soft-deleted-profiles?fromDate=${dates.fromDate}&toDate=${dates.toDate}`
    );
  } else {
    return http.get(`/soft-deleted-profiles?toDate=${dates.toDate}`);
  }
}

export function deleteProfile(id, moderatorId) {
  const profile = {
    profileId: id,
    moderatorId: moderatorId,
  };
  return http.post(`/hard-deleted-profiles`, profile);
}

export function publishProfile(id, moderatorId) {
  const profile = {
    profileId: id,
    moderatorId: moderatorId,
  };
  return http.post(`/published-profiles`, profile);
}

// export function rejectProfile(id,) {
//   const profile = { ...data };
//   profile.profileId = data;
//   return http.post(`/rejected-profiles`, profile);
// }

export function rejectProfile(id, moderatorId) {
  const profile = {
    profileId: id,
    moderatorId: moderatorId,
  };
  return http.post(`/rejected-profiles`, profile);
}
export function getUpdateProfiles() {
  return http.get(`/profile-free-text-field-update-requests`);
}

export function getUpdateProfile(id) {
  return http.get(`/profile-free-text-field-update-requests/${id}`);
}

export function respondUpdateRequest(data, id) {
  const profileData = {
    fieldStates: data.fieldStates,
    profileId: data.profileId,
    moderatorId: id,
  };
  return http.post(`/free-text-field-update-handled-profiles`, profileData);
}

export function getSearchedProfiles(searchValue) {
  return http.get(`/profiles?searchValue=${searchValue}`);
}

export function getActiveProfilesCount() {
  return http.get(`/profiles/Dashboard`);
}

export function getSoftDeletedProfilesCount() {
  return http.get(`/profiles/Dashboard/deleted`);
}

export function getHidedProfilesCount() {
  return http.get(`/profiles/Dashboard/hided`);
}

export function getMemberDetailsById(id) {
  return http.get(`/members/${id}`);
}