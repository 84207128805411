import { Col, Row, Table } from 'react-bootstrap';
import FavoriteIcon from '@mui/icons-material/Favorite';

const PartnerCriterionDetails = (props) => {
  const {
    ageLowerBound,
    ageUpperBound,
    heightLowerBound,
    heightUpperBound,
    hasChildren,
    religions,
    motherTongues,
    countries,
    qualifications,
    jobSectors,
    professions,
    dietHabbits,
    smokingHabbits,
    drinkingHabbits,
    creators,
    maritalStatuses,
  } = props.profile.partnerCriterion || {};

  function renderWithComma(value) {
    return value ? `${value}, ` : 'n/A';
  }

  return (
    <div>
      <div className="topic"><FavoriteIcon />&nbsp; Partner Criteria Details</div>
      <div className="content2">
        <div>Age Lower Bound:
          <span className='m-2'>{ageLowerBound?.name || "N/A"}</span>
        </div>

        <div>Age upper Bound:
          <span className='m-2'>{ageUpperBound?.name || "N/A"}</span>
        </div>

        <div>Height Lower Bound:
          <span className='m-2'>{heightLowerBound?.name || "N/A"}</span>
        </div>

        <div>Height Upper Bound:
          <span className='m-2'>{heightUpperBound?.name || "N/A"}</span>
        </div>

        <div>Has Children:
          <span className='m-2'>
            {hasChildren?.name || "N/A"}
          </span>
        </div>

        <div> Religions:
          {religions?.length === 0 ? "-" : religions?.map((r) => (
            <span key={r?.id} className='m-2'>
              {renderWithComma(r?.name)}
            </span>
          ))}
        </div>

        <div>Mother Tongues:
          {motherTongues?.length === 0 ? "-" : motherTongues?.map((mt) => (
            <span key={mt?.id} className='m-2'>
              {renderWithComma(mt?.name)}
            </span>
          ))}
        </div>

        <div>Countries:
          {countries?.length === 0 ? "-" : countries?.map((c) => (
            <span key={c?.id} className='m-2'>
              {renderWithComma(c?.name)}
            </span>
          ))}
        </div>

        <div>Qualifications:
          {qualifications?.length === 0 ? "-" : qualifications?.map((q) => (
            <span key={q?.id} className='m-2'>
              {renderWithComma(q?.name)}
            </span>
          ))}
        </div>

        <div>Job Sectors:
          {jobSectors?.length === 0 ? "-" : jobSectors?.map((js) => (
            <span key={js?.id} className='m-2'>
              {renderWithComma(js?.name)}
            </span>
          ))}
        </div>

        <div>Professions:
          {professions?.length === 0 ? "-" : professions?.map((p) => (
            <span key={p?.id} className='m-2'>
              {renderWithComma(p?.name)}
            </span>
          ))}
        </div>

        <div>Diet Habbits:
          {dietHabbits?.length === 0 ? "-" : dietHabbits?.map((dh) => (
            <span key={dh?.id} className='m-2'>
              {renderWithComma(dh?.name)}
            </span>
          ))}
        </div>

        <div>Smoking Habbits:
          {smokingHabbits?.length === 0 ? "-" : smokingHabbits?.map((sh) => (
            <span key={sh?.id} className='m-2'>
              {renderWithComma(sh?.name)}
            </span>
          ))}
        </div>

        <div>Drinking Habbits:
          {drinkingHabbits?.length === 0 ? "-" : drinkingHabbits?.map((dh) => (
            <span key={dh?.id} className='m-2'>
              {renderWithComma(dh?.name)}
            </span>
          ))}
        </div>

        <div>Created Bys:
          {creators?.length === 0 ? "-" : creators?.map((c) => (
            <span key={c?.id} className='m-2'>
              {renderWithComma(c?.name)}
            </span>
          ))}
        </div>

        <div>Marital Statuses:
          {maritalStatuses?.length === 0 ? "-" : maritalStatuses?.map((ms) => (
            <span key={ms?.id} className='m-2'>
              {renderWithComma(ms?.name)}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnerCriterionDetails;
