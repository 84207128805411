import http from "./httpService";

export function getUser(id) {
  return http.get(`users/${id}`);
}

export function getMe(me) {
  return http.get(`users/${me}`);
}

export function updateUser(id, data) {
  return http.put(`users/${id}`, data);
}

export function verifyUpdatedEmail(userId, token) {
  return http.post(
    `updated-email-verified-users?userId=${userId}&updatedEmailVerificationToken=${token}`
  );
}

export function getUsers() {
  return http.get("/users");
}
