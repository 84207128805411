import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProfileSearch from "./dashboard/profileSearch/profileSearch";
import { getProfile } from "../../services/profileService";
import "./moderatorHome.css";
import { toast } from "react-toastify";
import Card from "react-bootstrap/Card";
import { useModerator } from "../../context/moderatorContext";
import ContentLoader from "../common/contentLoader";
import {
  getSoftDeletedProfiles,
  getSoftDeletedProfilesCount,
} from "../../services/profileService";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import ReviewModal from "../moderator/photoRequest/ReviewModal";
import ReviewModalHorascope from "./photoRequest/ReviewModalHorascope";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ModeratorHome = () => {
  const {
    requestedProfiles,
    updateRequests,
    photoRequests,
    horoscopePhotos,
    holdedProfiles,
    moderatorLoading,
    deletedProfilesCount
  } = useModerator();
  // Get the timezone offset
  const offsetInMinutes = new Date().getTimezoneOffset();

  // Convert UTC time to local time
  const convertToLocalTime = (utcDate) => {
    const UtcDate = new Date(utcDate);
    const localDate = new Date(UtcDate.getTime() - offsetInMinutes * 60000);
    const localTimeString = localDate.toLocaleString();
    return localTimeString;
  };
  const [deletedProfiles, setDeletedProfiles] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [formatUpdateRequests, setFormatUpdateRequests] = useState([]);

  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showReviewModalHorascope, setShowReviewModalHorascope] =
    useState(false);
  const [option, setOption] = useState("");
  const [image, setImage] = useState();

  const handleCloseReviewModal = () => setShowReviewModal(false);
  const handleShowReviewModal = () => setShowReviewModal(true);
  const handleCloseReviewModalHorascope = () =>
    setShowReviewModalHorascope(false);
  const handleShowReviewModalHorascope = () =>
    setShowReviewModalHorascope(true);
  const [profileIdName, setprofileIdName] = useState();
  const [profile, setProfile] = useState();

  const fetchProfilesData = async () => {
    try {
      //get deleted profiles within previous 2 months
      //get to date
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      const year = tomorrow.getFullYear();
      const month = String(tomorrow.getMonth() + 1).padStart(2, "0"); // Month starts from 0
      const day = String(tomorrow.getDate()).padStart(2, "0");

      const tomorrowFormatted = `${year}-${month}-${day}`;
      //get from date
      const fromDate = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        today.getDate()
      );
      const yearFrom = fromDate.getFullYear();
      const monthFrom = String(fromDate.getMonth() + 1).padStart(2, "0"); // Month starts from 0
      const dayFrom = String(fromDate.getDate()).padStart(2, "0");
      const fromDateFormatted = `${yearFrom}-${monthFrom}-${dayFrom}`;
      const dates = {
        fromDate: fromDateFormatted,
        toDate: tomorrowFormatted,
      };
      const { data } = await getSoftDeletedProfiles(dates);
      setDeletedProfiles(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleReviewClick = async (profileId) => {
    try {
      setLoading(true);
      const response = await getProfile(profileId);
      if (response.status === 404) {
        toast.error("This profile is deleted");
        setLoading(false);
        return;
      } else if (response.status === 200) {
        setLoading(false);
        history.push({
          pathname: `/moderator/profile-update-request/${profileId}`,
          state: { profile: response.data },
        });
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
      toast.error("Error fetching profile");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfilesData();
  }, []);

  return (
    <>
      <div className="container-fluid mt-4 py-3">
        <ProfileSearch />
        {loading || moderatorLoading ? (
          <ContentLoader />
        ) : (
          <div>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Item>
                  <Card id="dashboard-card">
                    <Card.Body>
                      <div className="cardheader">
                        <div className="avatar profiles-icon bg-light-primary mx-2">
                          <div className="avatar-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2" // Corrected from stroke-width
                              strokeLinecap="round" // Corrected from stroke-linecap
                              strokeLinejoin="round" // Corrected from stroke-linejoin
                              className="feather feather-users font-medium-5"
                            >
                              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                              <circle cx="9" cy="7" r="4"></circle>
                              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                            </svg>
                          </div>
                        </div>
                        <Card.Title className="card-topic px-2 pt-3">
                          New Profiles &emsp;
                          <Chip
                            className="chip"
                            label={
                              requestedProfiles.length > 0 &&
                              requestedProfiles.length
                            }
                          />
                        </Card.Title>
                      </div>
                      <hr />
                      {requestedProfiles.length > 0 ? (
                        <>
                          <div className="fulldata">
                            <div>
                              <Card.Text className="card-para px-3">
                                {requestedProfiles.slice(0, 5).map((person) => (
                                  <div
                                    className="person"
                                    key={person.id}
                                    onClick={() => {
                                      history.push({
                                        pathname: `/moderator/requested-profile/${person.id}`,
                                        state: { profile: person },
                                      });
                                    }}
                                  >
                                    <Grid item xs={2}>
                                      <p3>{person.id} </p3>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <p3>
                                        {person.firstName || person.profileName}{" "}
                                      </p3>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <p3>
                                        {convertToLocalTime(person.createdAt)}{" "}
                                      </p3>
                                    </Grid>
                                  </div>
                                ))}
                              </Card.Text>
                            </div>
                            <div className="profbtnrow">
                              <Button
                                className="profbtn"
                                component={Link}
                                to="/moderator/requested-profiles"
                                variant="contained"
                              >
                                See all
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Card.Text className="card-para px-3">
                          <div className="person">
                            <p1>No new profiles</p1>
                          </div>
                        </Card.Text>
                      )}
                    </Card.Body>
                  </Card>
                </Item>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Item>
                  <Card id="dashboard-card">
                    <Card.Body>
                      <div className="cardheader">
                        <div className="avatar profile-success-icon  bg-light-sucess mx-2">
                          <div className="avatar-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-user-check"
                            >
                              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                              <circle cx="8.5" cy="7" r="4"></circle>
                              <polyline points="17 11 19 13 23 9"></polyline>
                            </svg>
                          </div>
                        </div>
                        <Card.Title className="card-topic px-2 pt-3">
                          Updated Profiles &emsp;
                          <Chip
                            className="chip"
                            label={
                              updateRequests.length > 0 && updateRequests.length
                            }
                          />
                        </Card.Title>
                      </div>
                      <hr />
                      {updateRequests.length > 0 ? (
                        <>
                          <div className="fulldata">
                            <div>
                              <Card.Text className="card-para px-3">
                                {updateRequests.slice(0, 5).map((person) => (
                                  <div
                                    className="person"
                                    key={person.id}
                                    onClick={() =>
                                      handleReviewClick(person.profile.id)
                                    }
                                  >
                                    <Grid item xs={2}>
                                      <p3>{person.profile.id}</p3>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <p3>
                                        {person.profile.firstName ||
                                          person.profile.profileName}{" "}
                                      </p3>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <p3>
                                        {convertToLocalTime(person.updatedAt)}{" "}
                                      </p3>
                                    </Grid>
                                  </div>
                                ))}
                              </Card.Text>
                            </div>
                            <div className="profbtnrow">
                              <Button
                                className="profbtn"
                                component={Link}
                                to="/moderator/profile-update-requests"
                                variant="contained"
                              >
                                See all
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Card.Text className="card-para px-3">
                          <div className="person">
                            <p1>No updated profiles</p1>
                          </div>
                        </Card.Text>
                      )}
                    </Card.Body>
                  </Card>
                </Item>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Item>
                  <Card id="dashboard-card">
                    <Card.Body>
                      <div className="cardheader">
                        <div className="avatar profile-error-icon bg-light-error mx-2">
                          <div className="avatar-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-user-x"
                            >
                              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                              <circle cx="8.5" cy="7" r="4"></circle>
                              <line x1="18" y1="8" x2="23" y2="13"></line>
                              <line x1="23" y1="8" x2="18" y2="13"></line>
                            </svg>
                          </div>
                        </div>
                        <Card.Title className="card-topic px-2 pt-3">
                          Deleted Profiles &emsp;
                          <Chip
                            className="chip"
                            label={
                              deletedProfilesCount > 0
                                ? deletedProfilesCount
                                : "0"
                            }
                          />
                        </Card.Title>
                      </div>
                      <hr />
                      <div className="fulldata" style={{ overflowY: "scroll" }}>
                        <div>
                          {deletedProfiles.length > 0 ? (
                            <>
                              <Card.Text className="card-para px-3">
                                {deletedProfiles.slice(0, 5).map((person) => (
                                  <div
                                    className="person"
                                    key={person.id}
                                    onClick={() => {
                                      history.push({
                                        pathname: `/moderator/deleted-profile/${person.profile.id}`,
                                        state: { profile: person.profile },
                                      });
                                    }}
                                  >
                                    <Grid item xs={1}>
                                      <p3>{person.profile.id} </p3>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <p3>
                                        {person.profile.firstName ||
                                          person.profile.profileName}{" "}
                                      </p3>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <p3>
                                        {" "}
                                        {convertToLocalTime(
                                          person.deletedAt
                                        )}{" "}
                                      </p3>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <p3> {person.reason} </p3>
                                    </Grid>
                                  </div>
                                ))}
                              </Card.Text>
                            </>
                          ) : (
                            <Card.Text className="card-para px-3">
                              <div className="person">
                                {deletedProfilesCount > 0 ? (
                                  <p1>
                                    No deleted profiles for last 3 months. Click
                                    see all for get other records
                                  </p1>
                                ) : (
                                  <p1>No deleted profiles.</p1>
                                )}
                              </div>
                            </Card.Text>
                          )}
                        </div>
                        {deletedProfilesCount > 0 && (
                          <div className="profbtnrow">
                            <Button
                              className="profbtn"
                              component={Link}
                              to="/moderator/deleted-profiles"
                              variant="contained"
                            >
                              See Older Records
                            </Button>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Item>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Item>
                  <Card id="dashboard-card">
                    <Card.Body>
                      <div className="cardheader">
                        <div className="avatar profile-error-icon bg-light-error mx-2">
                          <div className="avatar-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-user-x"
                            >
                              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                              <circle cx="8.5" cy="7" r="4"></circle>
                              <line x1="18" y1="8" x2="23" y2="13"></line>
                              <line x1="23" y1="8" x2="18" y2="13"></line>
                            </svg>
                          </div>
                        </div>
                        <Card.Title className="card-topic px-2 pt-3">
                          On Hold Profiles &emsp;
                          <Chip
                            className="chip"
                            label={
                              holdedProfiles.length > 0 && holdedProfiles.length
                            }
                          />
                        </Card.Title>
                      </div>
                      <hr />
                      {holdedProfiles.length > 0 ? (
                        <>
                          <div className="fulldata">
                            <div>
                              <Card.Text className="card-para px-3">
                                {holdedProfiles.slice(0, 5).map((person) => (
                                  <div
                                    className="person"
                                    key={person.profile.id}
                                    onClick={() => {
                                      history.push({
                                        pathname: `/moderator/holded-profile/${person.profile.id}`,
                                        state: { profile: person.profile },
                                      });
                                    }}
                                  >
                                    <Grid item xs={2}>
                                      <p3>{person.profile.id}</p3>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <p3>
                                        {person.profile.firstName ||
                                          person.profile.profileName}{" "}
                                      </p3>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <p3>
                                        {convertToLocalTime(person.holdedAt)}
                                      </p3>
                                    </Grid>
                                  </div>
                                ))}
                              </Card.Text>
                            </div>
                            <div className="profbtnrow">
                              <Button
                                className="profbtn"
                                component={Link}
                                to="/moderator/holded-profiles"
                                variant="contained"
                              >
                                See all
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Card.Text className="card-para px-3">
                          <div className="person">
                            <p1>No holded profiles</p1>
                          </div>
                        </Card.Text>
                      )}
                    </Card.Body>
                  </Card>
                </Item>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Item>
                  <Card id="dashboard-card">
                    <Card.Body>
                      <div className="cardheader">
                        <div className="avatar profile-error-icon bg-light-error mx-2">
                          <div className="avatar-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-user-x"
                            >
                              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                              <circle cx="8.5" cy="7" r="4"></circle>
                              <line x1="18" y1="8" x2="23" y2="13"></line>
                              <line x1="23" y1="8" x2="18" y2="13"></line>
                            </svg>
                          </div>
                        </div>
                        <Card.Title className="card-topic px-2 pt-3">
                          Updated Photos &emsp;
                          <Chip
                            className="chip"
                            label={
                              photoRequests.length > 0 && photoRequests.length
                            }
                          />
                        </Card.Title>
                      </div>
                      <hr />
                      {photoRequests.length > 0 ? (
                        <>
                          <div className="fulldata">
                            <div>
                              <Card.Text className="card-para px-3">
                                {photoRequests.slice(0, 5).map((person) => (
                                  <div
                                    className="person"
                                    key={person.id}
                                    onClick={async () => {
                                      setLoading(true);
                                      setShowReviewModal(true);
                                      setImage(person);
                                      const response = await getProfile(
                                        person.profileId
                                      );
                                      if (response.status === 404) {
                                        if (
                                          person.profileId === profileIdName
                                        ) {
                                          setLoading(false);
                                          setShowReviewModal(false);
                                          return;
                                        }
                                        toast.error("This profile is deleted");
                                        setLoading(false);
                                        setShowReviewModal(false);
                                        setprofileIdName(person.profileId);
                                      }
                                      if (response.status === 200) {
                                        setProfile(response.data);
                                        setLoading(false);
                                      }
                                    }}
                                  >
                                    <Grid item xs={2}>
                                      <p3>{person.id}</p3>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <img
                                        className="img"
                                        src={`data:image;base64,${person.fileContents}`}
                                        alt="UploadedImage"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <p3>
                                        {convertToLocalTime(person.uploadedAt)}{" "}
                                      </p3>
                                    </Grid>
                                  </div>
                                ))}
                              </Card.Text>
                            </div>
                            <div className="profbtnrow">
                              <Button
                                className="profbtn"
                                component={Link}
                                to="/moderator/photo-requests"
                                variant="contained"
                              >
                                See all
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Card.Text className="card-para px-3">
                          <div className="person">
                            <p1>No updated profile photos</p1>
                          </div>
                        </Card.Text>
                      )}
                    </Card.Body>
                  </Card>
                </Item>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Item>
                  <Card id="dashboard-card">
                    <Card.Body>
                      <div className="cardheader">
                        <div className="avatar profile-error-icon bg-light-error mx-2">
                          <div className="avatar-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-user-x"
                            >
                              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                              <circle cx="8.5" cy="7" r="4"></circle>
                              <line x1="18" y1="8" x2="23" y2="13"></line>
                              <line x1="23" y1="8" x2="18" y2="13"></line>
                            </svg>
                          </div>
                        </div>
                        <Card.Title className="card-topic px-2 pt-3">
                          Horoscopes photos &emsp;
                          <Chip
                            className="chip"
                            label={
                              horoscopePhotos.length > 0 &&
                              horoscopePhotos.length
                            }
                          />
                        </Card.Title>
                      </div>
                      <hr />
                      {horoscopePhotos.length > 0 ? (
                        <>
                          <div className="fulldata">
                            <div>
                              <Card.Text className="card-para px-3">
                                {horoscopePhotos.slice(0, 5).map((person) => (
                                  <div
                                    className="person"
                                    key={person.id}
                                    onClick={async () => {
                                      setLoading(true);
                                      setShowReviewModalHorascope(true);
                                      setImage(person);
                                      const response = await getProfile(
                                        person.profileId
                                      );
                                      if (response.status === 404) {
                                        if (
                                          person.profileId === profileIdName
                                        ) {
                                          setLoading(false);
                                          setShowReviewModalHorascope(false);
                                          return;
                                        }
                                        toast.error("This profile is deleted");
                                        setLoading(false);
                                        setShowReviewModalHorascope(false);
                                        setprofileIdName(person.profileId);
                                      }
                                      if (response.status === 200) {
                                        setProfile(response.data);
                                        setLoading(false);
                                      }
                                    }}
                                  >
                                    <Grid item xs={1}>
                                      <p3>{person.id}</p3>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <img
                                        className="img"
                                        src={`data:image;base64,${person.fileContents}`}
                                        alt="UploadedImage"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <p3>
                                        {convertToLocalTime(person.uploadedAt)}{" "}
                                      </p3>
                                    </Grid>
                                  </div>
                                ))}
                              </Card.Text>
                            </div>
                            <div className="profbtnrow">
                              <Button
                                className="profbtn"
                                component={Link}
                                to="/moderator/horoscope-requests"
                                variant="contained"
                              >
                                See all
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Card.Text className="card-para px-3">
                          <div className="person">
                            <p1>No updated horoscope photos</p1>
                          </div>
                        </Card.Text>
                      )}
                    </Card.Body>
                  </Card>
                </Item>
              </Grid>
            </Grid>
          </div>
        )}
        {!loading && (
          <>
            <ReviewModal
              show={showReviewModal}
              handleClose={handleCloseReviewModal}
              profile={profile}
              image={image}
              setOption={setOption}
            />
            <ReviewModalHorascope
              show={showReviewModalHorascope}
              handleClose={handleCloseReviewModalHorascope}
              profile={profile}
              image={image}
              setOption={setOption}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ModeratorHome;
