import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import './profileDetails.css';
import PhoneIcon from '@mui/icons-material/Phone';

const ContactDetails = (props) => {
  return (
    <>
      <div>
        <div className="topic"><PhoneIcon />&nbsp;Contact Details</div>
        <div className="content2">
          <div>Email:
            <span className="m-2">{props.member.email}</span>
          </div>
          <div>Phone Number:
            <span className="m-2">{props.member.phoneNumber}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
