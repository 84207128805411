import React, { useEffect, useState } from "react";
import { Link,useHistory } from "react-router-dom";
import { useModerator } from "../../../context/moderatorContext";
import { DataGrid } from "@material-ui/data-grid";
import { toast } from "react-toastify"; 
import "./profileUpdateRequests.css";
import { getProfile } from "../../../services/profileService";
import ContentLoader from "../../common/contentLoader";

const ProfileUpdateRequests = () => {
  const { updateRequests } = useModerator();

  const [formatUpdateRequests, setFormatUpdateRequests] = useState([]);
  const [timezoneOffset, setTimezoneOffset] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // Get the timezone offset
    const offsetInMinutes = new Date().getTimezoneOffset();
    setTimezoneOffset(offsetInMinutes);

    // Convert UTC time to local time
    const convertToLocalTime = (utcDate) => {
      const UtcDate = new Date(utcDate);
      const localDate = new Date(UtcDate.getTime() - (offsetInMinutes * 60000));
      const localTimeString = localDate.toLocaleString();
      return localTimeString;
    };

    // Process updateRequests and set formatUpdateRequests
    let newData = [];
    if (updateRequests == null) {
      setFormatUpdateRequests([]);
    } else {
      newData = updateRequests.map((field) => ({
        id: field.profile.id,
        profileName: field.profile.profileName,
        firstName: field.profile.firstName,
        surname: field.profile.surname,
        updatedAt: convertToLocalTime(field.updatedAt),
      }));
    }

    setFormatUpdateRequests(newData);
  }, [updateRequests, timezoneOffset]);

  const handleReviewClick = async (params) => {
    try {
      setLoading(true);
      const profileId = params.row.id;

      console.log(profileId)
  
      const response = await getProfile(profileId);

      console.log(response)
  
      if (response.status === 404) {
        toast.error("This profile is deleted");
        setLoading(false);
        return;
      } else if (response.status === 200) {
        setLoading(false);
        history.push({
          pathname: `/moderator/profile-update-request/${profileId}`,
          state: { profile: response.data }, 
        });
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
      toast.error("Error fetching profile");
      setLoading(false);
    }
  };
  
  

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "profileName",
      headerName: "Profile Name",
      width: 200,
      renderCell: (params) => {
        return <div className="userListUser">{params.row.profileName}</div>;
      },
    },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "surname", headerName: "Surname", width: 200 },
    { field: "updatedAt", headerName: "Updated At", width: 200 },
    {
      field: "review",
      headerName: "Review",
      width: 150,
      renderCell: (params) => {
        return (
          <button
            className="userListEdit"
            onClick={() => handleReviewClick(params)}
          >
            Review
          </button>
        );
      },
    },
  ];

  return (
    <div className="userList px-4 py-5 mt-5">
    <h4 className="table-title">Updated Profiles</h4>
    {loading ? (
      <ContentLoader /> 
    ) : (
      <DataGrid
        rows={formatUpdateRequests}
        disableSelectionOnClick
        columns={columns}
        pageSize={8}
      />
    )}
  </div>
  );
};

export default ProfileUpdateRequests;
