import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  getAttributeAudit,
  getProfileAudit,
  getProfileAudits,
  getAttributeAudits,
  getAllProfiles,
  getMembers,
} from "../../../services/auditService";
import { useState, useEffect } from "react";
import { useModerator } from "../../../context/moderatorContext";
import { getProfile } from "../../../services/profileService";
import Pagination from "react-bootstrap/Pagination";
import ContentLoader from "../../common/contentLoader";

export default function Attribute() {
  const [audit, setAudit] = useState(1);
  const { profiles } = useModerator();
  const [auditData, setAuditData] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [profileDetails, setprofileDetails] = useState([]);
  const [memberDetails, setMemberDetails] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  let indexOfLastItem = currentPage * itemsPerPage;
  let indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = auditData.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(auditData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (audit === 1) {
        let tempResponse = [];
        let dataArray = [];
        let memberArray = [];
        const response = await getProfileAudits();
        const Profiles = await getAllProfiles();
        const Members = await getMembers();
        if (Members.status === 200) {
          memberArray = Members.data;
          setMemberDetails(memberArray);
        }
        dataArray = Profiles.data;
        setprofileDetails(dataArray);
        tempResponse = response.data;
        tempResponse = tempResponse.filter((data) => data.action != null);
        setAuditData(tempResponse);
      }
      if (audit === 2) {
        const response = await getAttributeAudits();
        setAuditData(response.data);
      }
      setLoading(false);
    }
    fetchData();
  }, [audit]);

  const AuditChange = (event) => {
    setAuditData([]);
    setFromDate(null);
    setToDate(null);
    setAudit(event.target.value);
  };
  const auditorName = [
    { label: "Ishara" },
    { label: "Yonous" },
    { label: "Dinul" },
  ];
  const handlesearch = async () => {
    setLoading(true);
    try {
      let tempResponse = [];
      if (!fromDate || !toDate) {
        return;
      }

      if (audit === 1) {
        const response = await getProfileAudit(fromDate, toDate);
        tempResponse = response.data;
        tempResponse = tempResponse.filter((data) => data.action != null);
        setAuditData(tempResponse);
      } else if (audit === 2) {
        const response = await getAttributeAudit(fromDate, toDate);
        setAuditData(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  function getByProfileId(id) {
    const profile = profileDetails.find((e) => e.id === id);
    if (
      profile?.status === "HDeleted" ||
      profile?.isHided === true ||
      profile?.isSoftDeleted === true ||
      profile?.status === "Rejected"
    ) {
      const user = memberDetails.find((u) => u.id === profile.externalId);
      return user ? user.firstName : "Unknown";
    }
    return profile ? profile.profileName : "Unknown";
  }
  const MAX_PAGE_NUMBERS = 5;

  // Define the total number of pages
  const totalPageCount = Math.ceil(auditData.length / itemsPerPage);

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to handle next page
  const handleNextPage = () => {
    if (currentPage < totalPageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="container-fluid">
      <div className="row my-5 py-5 px-4">
        <div className="col-lg-2 col-sm-12">
          <FormControl sx={{ mt: 2 }} fullWidth color="warning">
            <InputLabel id="demo-simple-select-helper-label">
              Select Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={audit}
              label="Select Type"
              onChange={AuditChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={1}>Moderator Audit</MenuItem>
              <MenuItem value={2}>Attribute Audit</MenuItem>
            </Select>
          </FormControl>
        </div>
        {audit !== 2 && audit !== 1 ? (
          <div className="col-lg-3 col-sm-12">
            <Autocomplete
              disablePortal
              fullWidth
              id="combo-box-demo"
              options={auditorName}
              sx={{ mt: 2 }}
              renderInput={(params) => (
                <TextField {...params} label="Auditor Name" color="warning" />
              )}
            />
          </div>
        ) : null}

        <div className="col-lg-5 col-sm-12">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              sx={{ mt: 1 }}
              components={["DatePicker", "DatePicker"]}
            >
              <DatePicker
                label="From Date"
                color="warning"
                value={fromDate}
                onChange={(date) => setFromDate(date)}
              />
              <DatePicker
                label="To Date"
                color="warning"
                value={toDate}
                onChange={(date) => setToDate(date)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>

        <div className="col-lg-2 col-sm-12">
          <Button
            fullWidth
            sx={{ mt: 2, minHeight: 50 }}
            variant="contained"
            color="warning"
            onClick={handlesearch}
          >
            Search
          </Button>
        </div>
      </div>
      {loading ? (
        <ContentLoader />
      ) : (
        <div className="col-md-12">
          <Table responsive striped>
            <thead>
              {audit === 1 && (
                <tr>
                  <th className="text-center audit-table-heading">
                    Profile name/First name
                  </th>
                  <th className="text-center audit-table-heading">
                    Changed By
                  </th>
                  <th className="text-center audit-table-heading">
                    Previous Value
                  </th>
                  <th className="text-center audit-table-heading">New value</th>
                  <th className="text-center audit-table-heading">Action</th>
                  <th className="text-center audit-table-heading">Date</th>
                </tr>
              )}
              {audit === 2 && (
                <tr>
                  <th className="text-center audit-table-heading">
                    Changed By
                  </th>
                  <th className="text-center audit-table-heading">
                    Previous Value
                  </th>
                  <th className="text-center audit-table-heading">
                    Inserted Attribute/Updated Value
                  </th>
                  <th className="text-center audit-table-heading">Action</th>
                  <th className="text-center audit-table-heading">Date</th>
                </tr>
              )}
            </thead>
            <tbody>
              {audit === 2 &&
                auditData
                  .sort((a, b) => new Date(b.time) - new Date(a.time)) // sort in reverse chronological order
                  .slice(
                    currentPage * itemsPerPage - itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((audit) => (
                    <tr key={audit.id}>
                      <td className="text-center audit-table-col">Moderator</td>
                      {audit.actions === "Inserted" ||
                        audit.actions === "Disabled" ||
                        audit.actions === "Underreviewed" ? (
                        <>
                          <td className="text-center audit-table-col"></td>
                          <td className="text-center audit-table-col">
                            {audit.changes}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="text-center audit-table-col">
                            {audit.changes.split("->")[0]}
                            <br />
                          </td>
                          <td className="text-center audit-table-col">
                            {audit.changes.split("->")[1]}
                          </td>
                        </>
                      )}
                      <td className="text-center audit-table-col">
                        <span
                          style={{ padding: "10px 8px" }}
                          className={`badge ${audit.actions === "Disabled" ||
                            audit.actions === "Underreviewed"
                            ? "badge-danger"
                            : "badge-success"
                            }`}
                        >
                          {audit.actions.charAt(0).toUpperCase() +
                            audit.actions.slice(1)}
                        </span>
                      </td>
                      <td className="text-center audit-table-col">
                        {new Date(audit.time).toLocaleDateString("en-US")}
                      </td>
                    </tr>
                  ))}

              {audit === 1 &&
                auditData
                  .sort((a, b) => new Date(b.time) - new Date(a.time))
                  .slice(
                    currentPage * itemsPerPage - itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((audit, index) => {
                    return (
                      <tr key={audit.id}>
                        <td className="text-center audit-table-col">
                          {getByProfileId(audit.profileId)}
                        </td>
                        <td className="text-center audit-table-col">
                          Moderator
                        </td>
                        <td className="text-center audit-table-col">
                          {audit.action === "Profile holded" ||
                            audit.action === "Profile deleted" ||
                            audit.action === "Approved profile" ||
                            audit.action === "Rejected profile" ||
                            audit.action === "Profile unholded"
                            ? "-"
                            : audit.changes.split("->")[0]}
                        </td>
                        <td className="text-center audit-table-col">
                          {audit.action === "Profile holded" ||
                            audit.action === "Profile deleted" ||
                            audit.action === "Approved profile" ||
                            audit.action === "Rejected profile" ||
                            audit.action === "Profile unholded"
                            ? "-"
                            : audit.changes.split("->")[1]}
                        </td>
                        <td className="text-center audit-table-col">
                          <span
                            style={{ padding: "10px 8px" }}
                            className="badge badge-success"
                          >
                            {audit.action}
                          </span>
                        </td>
                        <td className="text-center audit-table-col">
                          {new Date(audit.time).toLocaleDateString("en-US")}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
          <div className="row">
            <Pagination>
              <Pagination.Prev
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              />
              {pageNumbers.map((num) => {
                if (
                  num === 1 || // Always show the first page
                  num === totalPageCount || // Always show the last page
                  (num >= currentPage - MAX_PAGE_NUMBERS &&
                    num <= currentPage + MAX_PAGE_NUMBERS)
                ) {
                  return (
                    <Pagination.Item
                      key={num}
                      active={num === currentPage}
                      onClick={() => handlePageChange(num)}
                    >
                      {num}
                    </Pagination.Item>
                  );
                } else if (
                  num === currentPage - MAX_PAGE_NUMBERS - 1 ||
                  num === currentPage + MAX_PAGE_NUMBERS + 1
                ) {
                  // Show an ellipsis (...) before and after the hidden page numbers
                  return <Pagination.Ellipsis key={num} disabled />;
                }
                return null; // Hide other page numbers
              })}
              <Pagination.Next
                onClick={handleNextPage}
                disabled={currentPage === totalPageCount}
              />
            </Pagination>
          </div>
        </div>
      )}
    </div>
  );
}
