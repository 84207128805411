import React from "react";
import { Modal, Button } from "react-bootstrap";
import {
  approvePhoto,
  getNewPhotos,
  rejectPhoto,
} from "../../../services/photoService";
import { toast } from "react-toastify";
import { useModerator } from "../../../context/moderatorContext";

const ConfirmationModal = ({ show, handleClose, option, image }) => {
  const { photoRequests, setPhotoRequests, setPhotoCount, moderatorLoading } =
    useModerator();

  const approvePhotoRequest = async (id) => {
    let obj = {};
    obj.photoId = id;
    const approvePhotoResponse = await approvePhoto(obj);
    if (approvePhotoResponse.status === 201) {
      handleClose();
      toast.success("This photo accepted sucessfully!!!");
      const photoRequestResponse = await getNewPhotos(10, 1);
      if (photoRequestResponse.status === 200) {
        setPhotoRequests(photoRequestResponse.data.data);
        setPhotoCount(photoRequestResponse.data.count);
      }
    } else {
      toast.error("Unable to approve this photo");
    }
  };

  const rejectPhotoRequest = async (id) => {
    let obj = {};
    obj.photoId = id;
    const rejectPhotoResponse = await rejectPhoto(obj);
    if (rejectPhotoResponse.status === 201) {
      handleClose();
      toast.success("This photo rejected sucessfully!!!");
      const photoRequestResponse = await getNewPhotos(10, 1);
      if (photoRequestResponse.status === 200) {
        setPhotoRequests(photoRequestResponse.data.data);
        setPhotoCount(photoRequestResponse.data.count);
      }
    } else {
      toast.error("Unable to reject this photo");
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {option === "ACCEPT" && "Do you want to approve this Photo?"}
        {option === "REJECT" && "Do you want to reject this Photo?"}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            if (option === "ACCEPT") {
              approvePhotoRequest(image.id);
            } else if (option === "REJECT") {
              rejectPhotoRequest(image.id);
            }
          }}
        >
          Yes
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleClose();
          }}
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
