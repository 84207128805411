import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModerator } from "../../../context/moderatorContext";
import "./holdedProfiles.css";
import ContentLoader from "../../common/contentLoader";
import { getProfile } from "../../../services/profileService";
import { toast } from "react-toastify";

const HoldedProfiles = () => {
  const { holdedProfiles } = useModerator();
  const [formatHoldedRequests, setFormatHoldedRequests] = useState([]);
  const [loading, setLoading] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const offsetInMinutes = new Date().getTimezoneOffset();

    // Convert UTC time to local time
    function convertToLocalTime(utcDate) {
      const UtcDate = new Date(utcDate);
      const localDate = new Date(UtcDate.getTime() - offsetInMinutes * 60000);
      const localTimeString = localDate.toLocaleString();
      return localTimeString;
    }

    let newData = [];
    holdedProfiles &&
      holdedProfiles.map((field) => {
        let obj = {};
        obj.id = field.profile.id;
        obj.profileName = field.profile.profileName;
        obj.firstName = field.profile.firstName;
        obj.surname = field.profile.surname;
        obj.holdedAt = convertToLocalTime(field.holdedAt);
        newData.push(obj);
      });
    setFormatHoldedRequests(newData);
  }, [holdedProfiles]);

  const handleReviewClick = async (params) => {
    try {
      setLoading(true);
      const profileId = params.row.id;

      const response = await getProfile(profileId);

      if (response.status === 404) {
        toast.error("This profile is deleted");
        setLoading(false);
        return;
      } else if (response.status === 200) {
        setLoading(false);
        history.push({
          pathname: `/moderator/holded-profile/${params.row.id}`,
          state: { profile: params.row },
        });
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
      toast.error("Error fetching profile");
      setLoading(false);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "profileName",
      headerName: "Profile Name",
      width: 200,
      renderCell: (params) => {
        return <div className="userListUser">{params.row.profileName}</div>;
      },
    },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "surname", headerName: "Surname", width: 200 },
    { field: "holdedAt", headerName: "On Hold At", width: 200 },
    {
      field: "review",
      headerName: "Review",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              className="userListEdit"
              onClick={() => handleReviewClick(params)}
            >
              Review
            </button>
          </>
        );
      },
    },
  ];

  return (
    <div className="userList  py-5 px-4 mt-5">
      <h4 className="table-title">On Hold Profiles</h4>
      {loading ? (
        <ContentLoader />
      ) : (
        <DataGrid
          rows={formatHoldedRequests}
          disableSelectionOnClick
          columns={columns}
          pageSize={8}
        />
      )}
    </div>
  );
};

export default HoldedProfiles;
