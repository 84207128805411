import { yupResolver } from "@hookform/resolvers/yup";
import { MailOutline, PermIdentity, Phone, Publish } from "@material-ui/icons";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import "yup-phone";
import { useModerator } from "../../../context/moderatorContext";
import { updateUser } from "../../../services/userService";
import Input from "../../common/input";
import "./ownProfile.css";

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

const schema = yup.object({
  firstName: yup
    .string()
    .required()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .max(50)
    .label("First Name"),
  lastName: yup
    .string()
    .required()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .max(50)
    .label("Last Name"),
  phoneNumber: yup.string().min(7).max(15).required().matches(phoneRegExp,"Invalid phone number. Please enter a valid phone number in the following formats: [+][Country Code][Number] for international numbers or [Number] for local numbers. You can include parentheses, dashes, dots, slashes, or spaces as separators"),
});

const OwnProfile = () => {
  const { myProfile, setMyProfile } = useModerator();
  const [changed, setChanged] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    handleSubmit,
    errors,
    register,
    watch,
    setError,
    control,
    setValue,
    getValues,
  } = useForm({ defaultValues: myProfile, resolver: yupResolver(schema) });

  const onSubmit = async (data, e) => {
    const response = await updateUser(myProfile.id, data);
    if (response.status == 200) {
      myProfile.firstName = data.firstName;
      myProfile.lastName = data.lastName;
      myProfile.phoneNumber = data.phoneNumber;
      // myProfile.email = data.email;
      if (myProfile.email != data.email) {
        setChanged(true);
        setShow(true);
      }
      setMyProfile(myProfile);
      e.target.reset();
    }
  };


  return (
    <>
      <div className="user mt-5 pt-5 px-4">
        <div className="row">
          <div className="userTitleContainer">
            <h1 className="userTitle">My Profile</h1>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="userShow my-4">
              <div className="userShowTop">
                <img
                  src="https://i.pinimg.com/736x/3f/94/70/3f9470b34a8e3f526dbdb022f9f19cf7.jpg"
                  alt=""
                  className="userShowImg"
                />
                <div className="userShowTopTitle">
                  <span className="userShowUsername">{myProfile.firstName}</span>
                  <span className="userShowUserTitle">Content Moderator</span>
                </div>
              </div>
              <div className="userShowBottom">
                <span className="userShowTitle">First Name</span>
                <div className="userShowInfo">
                  <PermIdentity className="userShowIcon" />
                  <span className="userShowInfoTitle">{myProfile.firstName}</span>
                </div>
                <span className="userShowTitle">Last Name</span>
                <div className="userShowInfo">
                  <PermIdentity className="userShowIcon" />
                  <span className="userShowInfoTitle">{myProfile.lastName}</span>
                </div>

                <span className="userShowTitle">Contact Details</span>

                <div className="userShowInfo">
                  <MailOutline className="userShowIcon" />
                  <span className="userShowInfoTitle">{myProfile.email}</span>
                  {/* {changed && <span className="alert">Verify</span>} */}
                </div>
                <div className="userShowInfo">
                  <Phone className="userShowIcon" />
                  <span className="userShowInfoTitle">
                    {myProfile.phoneNumber}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 col-sm-12">
            <div className="userUpdate my-4">
              <span className="userUpdateTitle">Edit</span>
              <form className="userUpdateForm" onSubmit={handleSubmit(onSubmit)}>
                <div className="userUpdateLeft">
                  <div className="userUpdateItem">
                    <Input
                      name="firstName"
                      label="First Name"
                      reg={register}
                      defaultValue={myProfile.firstName}
                      className="userUpdateInput"
                      error={errors.firstName?.message}
                    />
                  </div>
                  <div className="userUpdateItem">
                    <Input
                      name="lastName"
                      label="Last Name"
                      reg={register}
                      defaultValue={myProfile.lastName}
                      className="userUpdateInput"
                      error={errors.lastName?.message}
                    />
                  </div>
                  <div className="userUpdateItem">
                    <Input
                      name="email"
                      label="Email"
                      // reg={register}
                      defaultValue={myProfile.email}
                      className="userUpdateInput"
                      disable={true}
                      error={errors.email?.message}
                    />
                  </div>
                  <div className="userUpdateItem">
                    <Input
                      name="phoneNumber"
                      label="Phone"
                      reg={register}
                      defaultValue={myProfile.phoneNumber}
                      className="userUpdateInput"
                      error={errors.phoneNumber?.message}
                    />
                  </div>
                </div>
                <div className="userUpdateRight">
                  <div className="userUpdateUpload">
                    <img
                      className="userUpdateImg"
                      src="https://i.pinimg.com/736x/3f/94/70/3f9470b34a8e3f526dbdb022f9f19cf7.jpg"
                      alt=""
                    />
                    <label htmlFor="file">
                      <Publish className="userUpdateIcon" />
                    </label>
                    <input type="file" id="file" style={{ display: "none" }} />
                  </div>
                  <button type="submit" className="userUpdateButton">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
            
        </div>
        <div className="userContainer">
         
          
        </div>
      </div>
    </>
  );
};

export default OwnProfile;
