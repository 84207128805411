import { DataGrid } from "@material-ui/data-grid";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useModerator } from "../../../context/moderatorContext";
import "./profiles.css";

const Profiles = () => {
  const [show, setShow] = useState(false);
  const { profiles } = useModerator();

  // useEffect(async () => {

  // }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "profileName",
      headerName: "Profile Name",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            <img
              className="userListImg"
              src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
            />
            {params.row.profileName}
          </div>
        );
      },
    },
    { field: "firstName", headerName: "First Name", width: 180 },
    { field: "surname", headerName: "Surname", width: 180 },
    { field: "status", headerName: "Status", width: 180 },
    {
      field: "view",
      headerName: "View",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{
                pathname: `/moderator/profile/${params.row.id}`,
                state: { profile: params.row },
              }}
            >
              <button className="userListEdit">View</button>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="userList mt-5">
        <DataGrid
          rows={profiles}
          disableSelectionOnClick
          columns={columns}
          pageSize={8}
          checkboxSelection
        />
      </div>

      {/* <div className="home">
        <table className="col-md-10">
          {show && <div>No Profiles Found</div>}
          {profiles.length != 0 && (
            <div className="table">
              <tr>
                <th scope="col">Profile ID</th>
                <th scope="col">Profile Name</th>
                <th scope="col">First Name</th>
                <th scope="col">Surname</th>
                <th scope="col">Status</th>
              </tr>
              {profiles &&
                profiles.map((profile) => {
                  return (
                    <tr>
                      <td>
                        <label>{profile.id}</label>
                      </td>
                      <td>
                        <label>{profile.profileName}</label>
                      </td>
                      <td>
                        <label>{profile.firstName}</label>
                      </td>
                      <td>
                        <label>{profile.surname}</label>
                      </td>
                      <td>
                        <label>{profile.status}</label>
                      </td>
                      <td>
                        <Link
                          className="btn btn-primary"
                          to={{
                            pathname: `/moderator/profile/${profile.id}`,
                            state: { profile: profile },
                          }}
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </div>
          )}
        </table>
      </div> */}
    </>
  );
};

export default Profiles;
