import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { verifyUserRole } from "../../services/userRoleService";
import Input from "../common/input";

const schema = yup.object({
  firstName: yup.string().required().max(50).label("First Name"),
  lastName: yup.string().required().max(50).label("Last Name"),
});

const VerificationForm = () => {
  const {
    handleSubmit,
    errors,
    register,
    watch,
    setError,
    control,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data, id) => {
    await verifyUserRole(data, 31);
  };

  return (
    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-10">
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="firstName"
            label="First Name"
            reg={register}
            error={errors.firstName?.message}
          />
          <Input
            name="lastName"
            label="Last Name"
            reg={register}
            error={errors.lastName?.message}
          />
          <Input name="role" label="Role" value="Moderator" reg={register} />
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default VerificationForm;
