import http from "./httpService";

/*E.g. /creators?status=Enabled OR /creators?status=1 */
//Get profession attribute
export function getProfession(id) {
  return http.get(`/professions/${id}`);
}
/**
 * Get Attributes filtered by status
 * @param {attCat} Category of attribute (e.g. Creators, Religions)
 * @param {statusName} Status to be filtered by
 */
export function getAttributesByStatus(attCat, statusName) {
  return http.get(`/${attCat}?status=${statusName}`);
}
/**
 * Get All Attribute Status Names
 */
export function getAttributeStatuses() {
  return http.get(`/meta-data/statuses`);
}
/**
 * Update an Attribute
 * @param {attCat} Category of attribute (e.g. Creators, Religions)
 * @param {attId} Id of attribute
 * @param {body} Object with 'name' and 'status' E.g:{name:"newName", status="Enabled"}
 */
export function updateAttribute(attCat, attid, body, moderatorId) {
  return http.put(`/${attCat}/${attid}/${moderatorId}`, body);
}

/**
 * Add new Attribute
 * @param {attCat} Category of attribute (e.g. Creators, Religions)
 * @param {body} Object with 'name' and 'status' E.g:{name:"newName", status="Enabled"}
 */
export function addAttribute(attCat, body, moderatorId) {
  return http.post(`/${attCat}/${moderatorId}`, body);
}
