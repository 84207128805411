import React, { useContext, useEffect, useState } from "react";
import { getHoroscopePhotos, getNewPhotos } from "../services/photoService";
import {
  getHoldedProfiles,
  getProfiles,
  getRequestedProfiles,
  getSoftDeletedProfiles,
  getUpdateProfiles,
} from "../services/profileService";
import { getMe } from "../services/userService";

const ModeratorContext = React.createContext();

export const ModeratorProvider = ({ children }) => {
  const [profiles, setProfiles] = useState([]);
  const [requestedProfiles, setRequestedProfiles] = useState([]);
  const [updateRequests, setUpdateRequests] = useState([]);
  const [photoRequests, setPhotoRequests] = useState([]);
  const [photoCount, setPhotoCount] = useState();
  const [holdedProfiles, setHoldedProfiles] = useState([]);
  const [deletedProfiles, setDeletedProfiles] = useState([]);
  const [myProfile, setMyProfile] = useState([]);
  const [moderatorLoading, setModeratorLoading] = useState(true);
  const [horoscopePhotos, setHoroscopePhotos] = useState([]);
  const [deletedProfilesCount, setDeletedProfilesCount] = useState(0);

  useEffect(() => {
    async function fetchData(params) {
      setModeratorLoading(true);
      const profilesResponse = await getProfiles();
      if (profilesResponse.status === 200) {
        setProfiles(profilesResponse.data);
      }
      const requestedProfilesResponse = await getRequestedProfiles();
      if (requestedProfilesResponse.status === 200) {
        setRequestedProfiles(requestedProfilesResponse.data);
      }
      const updatedProfilesResponse = await getUpdateProfiles();
      if (updatedProfilesResponse.status === 200) {
        setUpdateRequests(updatedProfilesResponse.data);
      }
      const holdedProfilesResponse = await getHoldedProfiles();
      if (holdedProfilesResponse.status === 200) {
        setHoldedProfiles(holdedProfilesResponse.data);
      }
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      const year = tomorrow.getFullYear();
      const month = String(tomorrow.getMonth() + 1).padStart(2, "0"); // Month starts from 0
      const day = String(tomorrow.getDate()).padStart(2, "0");

      const tomorrowFormatted = `${year}-${month}-${day}`;

      let obj = { toDate: tomorrowFormatted };
      const deletedProfilesResponse = await getSoftDeletedProfiles(obj);
      if (deletedProfilesResponse.status === 200) {
        setDeletedProfiles(deletedProfilesResponse.data);
        setDeletedProfilesCount(deletedProfilesResponse.data.length);
      }

      const myProfilesResponse = await getMe("me");
      if (myProfilesResponse.status === 200) {
        setMyProfile(myProfilesResponse.data);
      }

      const photoRequestResponse = await getNewPhotos(10, 1);
      if (photoRequestResponse.status === 200) {
        setPhotoRequests(photoRequestResponse.data.data);
        setPhotoCount(photoRequestResponse.data.count);
      }
      const horoscopeResponse = await getHoroscopePhotos(10, 1);
      if (horoscopeResponse.status === 200) {
        setHoroscopePhotos(horoscopeResponse.data.data);
      }
      setModeratorLoading(false);
    }
    fetchData();
  }, []);
  return (
    <ModeratorContext.Provider
      value={{
        profiles,
        setProfiles,
        requestedProfiles,
        setRequestedProfiles,
        updateRequests,
        setUpdateRequests,
        holdedProfiles,
        setHoldedProfiles,
        deletedProfiles,
        setDeletedProfiles,
        myProfile,
        setMyProfile,
        photoRequests,
        setPhotoRequests,
        photoCount,
        setPhotoCount,
        moderatorLoading,
        setModeratorLoading,
        horoscopePhotos,
        setHoroscopePhotos,
        deletedProfilesCount,
        setDeletedProfilesCount
      }}
    >
      {children}
    </ModeratorContext.Provider>
  );
};

export const useModerator = () => useContext(ModeratorContext);
