import { DataGrid } from "@material-ui/data-grid";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import { useModerator } from "../../../context/moderatorContext";
import "./requestedProfiles.css";

const RequestedProfiles = () => {
  const [show, setShow] = useState(false);
  const { requestedProfiles } = useModerator();
  const [timezoneOffset, setTimezoneOffset] = useState(null);
  const [utcDate, setUtcDate] = useState(null);

  //getting the time zone difference
  useEffect(() => {
    const offsetInMinutes = new Date().getTimezoneOffset();
    setTimezoneOffset(offsetInMinutes);
  }, []);

  //converting UTC time to local time
  function convertToLocalTime(utcDate) {
    const UtcDate = new Date(utcDate);
    const localDate = new Date(UtcDate.getTime() - timezoneOffset * 60000);
    const localTimeString = localDate.toLocaleString();
    return localTimeString;
  }

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "profileName",
      headerName: "Profile Name",
      width: 200,
      renderCell: (params) => {
        return <div className="userListUser">{params.row.profileName}</div>;
      },
    },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "surname", headerName: "Surname", width: 200 },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {convertToLocalTime(params.row.createdAt)}
          </div>
        );
      },
    },
    {
      field: "review",
      headerName: "Review",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={{
                pathname: `/moderator/requested-profile/${params.row.id}`,
                state: { profile: params.row },
              }}
            >
              <button className="userListEdit">Review</button>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className="userList py-5 px-3 mt-5">
      <h4 className="table-title">New Profiles</h4>

      <DataGrid
        rows={requestedProfiles}
        disableSelectionOnClick
        columns={columns}
        pageSize={8}
        // checkboxSelection
      />
    </div>
  );
};

export default RequestedProfiles;
