import http from "./httpService";

export function requestUserRole(data) {
  return http.post(`requested-user-roles`, data);
}

export function getUserRoleRequests() {
  return http.get(`await-user-roles`);
}

export function approveUserRoleRequest(id) {
  return http.put(`approved-user-roles/${id}`);
}

export function revokeUserRoleRequest(id) {
  return http.delete(`revoked-user-roles/${id}`);
}

export function getActiveUsers() {
  return http.get(`active-user-roles`);
}

export function inviteModerator(data) {
  return http.post(`invited-user-roles`, data);
}

export function verifyUserRole(data, id) {
  return http.put(`verified-user-roles/${id}`, data);
}

export function deActivateModerator(id) {
  return http.delete(`revokeModerator/${id}`);
}

export function activateModerator(id) {
  return http.put(`reinstigateModerator/${id}`);
}