import React from 'react'

const RoleInvitation = () => {
  return (
    <div className='row'>
      <div className='col-md-2'></div>
      <div className='col-md-10'>
        <div className='form'>
          <div class='form-group row'>
            <label class='col-sm-4 col-form-label'>Role</label>
            <label class='col-sm-8 col-form-label'>Moderator</label>
          </div>
          <div class='form-group row'>
            <label class='col-sm-4 col-form-label'>Invited By</label>
            <label class='col-sm-8 col-form-label'>Admin email</label>
          </div>
          <div className='form-group row'>
            <div className='col-sm-6'>
              <button className='btn btn-primary'>Accept</button>
            </div>
            <div className='col-sm-6'>
              <button className='btn btn-primary'>Reject</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoleInvitation
