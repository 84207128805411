import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { getRoles } from "../../services/roleService";
import { requestUserRole } from "../../services/userRoleService";
import Input from "../common/input";
import Select from "../common/select";

const schema = yup.object({
  email: yup.string().email().required().label("Email"),
  role: yup.object().required(),
  firstName: yup.string().required().max(50).label("First Name"),
  lastName: yup.string().required().max(50).label("Last Name"),
});

const ModeratorRequestForm = (props) => {
  const [roles, setRoles] = useState([]);
  const {
    handleSubmit,
    errors,
    register,
    watch,
    setError,
    control,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(async () => {
    const { data } = await getRoles();
    setRoles(data);
  }, []);

  const onSubmit = async (data, e) => {
    data.roleId = data.role.id;
    delete data.role;
    requestUserRole(data);
    e.target.reset();
  };

  return (
    <div className="row mt-5">
      <div className="col-md-2"></div>
      <div className="col-md-10">
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="firstName"
            label="First Name"
            reg={register}
            error={errors.firstName?.message}
          />
          <Input
            name="lastName"
            label="Last Name"
            reg={register}
            error={errors.lastName?.message}
          />
          <Input
            name="email"
            label="Email"
            reg={register}
            error={errors.email?.message}
          />
          <Select
            name="role"
            label="Role"
            options={roles}
            control={control}
            error={errors.role?.message}
            setValue={setValue}
            getValues={getValues}
          />
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ModeratorRequestForm;
