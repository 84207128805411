import React from "react";
import { Redirect, Route } from "react-router";
import { useAuth } from "../context/authContext";

const PrivateRoute = ({
  component: Component,
  // navBar: NavBar,
  loggedIn,
  ...rest
}) => {
  const { account, setAccount, isModerator, isLoadingModerator } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoadingModerator ? (
          isModerator === true ? (
            <>
              {/* <NavBar {...rest} {...props} /> */}
              <Component {...rest} {...props} />
            </>
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        ) : (
          <div></div>
        )
      }
    />
  );
};

export default PrivateRoute;
