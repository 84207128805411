import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { useModerator } from "../../../context/moderatorContext";
import {
  getUpdateProfile,
  getUpdateProfiles,
  respondUpdateRequest,
} from "../../../services/profileService";
import ApproveRejectButton from "../../common/approveRejectButton";
import "./profileUpdateRequests.css";
import ContentLoader from './../../common/contentLoader';
import { toast } from 'react-toastify';
import { getProfession } from "../../../services/attributeService";

const ProfileUpdateRequest = (props) => {
  const { myProfile } = useModerator();
  const { setUpdateRequests } = useModerator();
  const [updateRequest, setUpdateRequest] = useState();
  const [item, setItem] = useState([]);
  const [show, setShow] = useState(false);
  const [confirmationShow, setConfirmationShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleConfirmationClose = () => setConfirmationShow(false);
  const handleConfirmationShow = () => setConfirmationShow(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await getUpdateProfile(props.location.state.profile.id);
      console.log(data.fields);
      const updatedField = await Promise.all(
        data.fields.map(async (field) => {
          if (field.id === "PROFESSION") {
            const professionValue = await getProfession(field.value);
            return {
              id: field.id,
              label: field.label,
              value: professionValue.data.name,
            };
          }
          return {
            id: field.id,
            label: field.label,
            value: field.value,
          };
        })
      );
      setUpdateRequest(updatedField);
      const updatedItems = data.fields.map((field) => {
        return {
          id: field.id,
          isApproved: false,
          value: field.value,
        };
      });
      setItem(updatedItems);
      setLoading(false);
    }
    fetchData();
  }, []);

  const handleToggle = (id, isApproved) => {
    const field = item.find((object) => object.id === id);
    field.isApproved = isApproved;
  };

  const handleSubmit = async () => {
    const body = {
      profileId: props.location.state.profile.id,
      fieldStates: item,
    };
    const response = await respondUpdateRequest(body, myProfile.id);
    if (response.status == 201) {
      toast.success("This Profile Updated Successfully!!!");
      const updatedProfilesResponse = await getUpdateProfiles();
      if ((updatedProfilesResponse.status = 200)) {
        setUpdateRequests(updatedProfilesResponse.data);
      }
      history.replace(`/moderator/profile-update-requests`);
      // handleShow();
    } else {
      toast.error("Unable to update this profile");
    }
  };

  return (
    <>
      {loading && <ContentLoader className="spinner" />}
      {!loading &&
        <>
          {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>This Profile is updated Successfully!!!</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                history.replace(`/moderator/profile-update-requests`);
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal> */}
          <Modal show={confirmationShow} onHide={handleConfirmationClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              Do you want to approve or reject these updates?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => handleSubmit()}
              >
                Yes
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleConfirmationClose();
                }}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="home mt-5">
            <div className="col-md-10">
              <table class="table table-responsive">
                <tr>
                  <th scope="col">Profile ID: {props.location.state.profile.id}</th>
                </tr>
                {updateRequest &&
                  updateRequest.map((field) => {
                    return (
                      <tr>
                        <td>
                          <label>{field.label}</label>
                        </td>
                        <td>
                          <label>{field.value}</label>
                        </td>
                        <td>
                          <ApproveRejectButton
                            onToggle={handleToggle}
                            field={field}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </table>
              <button className="btn btn-primary" onClick={() => handleConfirmationShow()}>
                Submit
              </button>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default ProfileUpdateRequest;
