import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProfileSearch from "./dashboard/profileSearch/profileSearch";
import "./moderatorHome.css";
import Card from "react-bootstrap/Card";
import { useModerator } from "../../context/moderatorContext";
import ContentLoader from "../common/contentLoader";
import {
  getActiveProfilesCount,
  getHidedProfilesCount,
  getSoftDeletedProfilesCount,
} from "../../services/profileService";



const ModeratorHome = () => {
  const { requestedProfiles } = useModerator();
  const [activeProfilesCount, setActiveProfilesCount] = useState(null);
  const [deletedProfilesCount, setDeletedProfilesCount] = useState(null);
  const [hidedProfileCount, setHidedProfileCount] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchProfilesData = async () => {
    try {
      const [
        activeProfilesResponse,
        deletedProfilesResponse,
        hidedProfilesResponse,
      ] = await Promise.all([
        getActiveProfilesCount(),
        getSoftDeletedProfilesCount(),
        getHidedProfilesCount(),
      ]);

      setActiveProfilesCount(activeProfilesResponse.data.length);
      setDeletedProfilesCount(deletedProfilesResponse.data.deletedCount);
      setHidedProfileCount(hidedProfilesResponse.data.hidedCount);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfilesData();
  }, []);
  return (
    <>
      <div className="container-fluid mt-4 py-3">
        <ProfileSearch />
        {loading ? (
          <ContentLoader />
        ) : (
          <div className="row">
            <div className="col-md-3 my-2">
              <Link to="/moderator/active-profile" style={{ textDecoration: "none" }}>
                {/* <CardWrapper> */}
                <div className="CardWrapper"> {/* Apply the CardWrapper class here */}
                  <Card id="dashboard-card">
                    <Card.Body>
                      <div className="avatar profiles-icon bg-light-primary mx-2">
                        <div className="avatar-content">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2" // Corrected from stroke-width
                            strokeLinecap="round" // Corrected from stroke-linecap
                            strokeLinejoin="round" // Corrected from stroke-linejoin
                            className="feather feather-users font-medium-5"
                          >
                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                            <circle cx="9" cy="7" r="4"></circle>
                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                          </svg>
                        </div>
                      </div>
                      <Card.Title className="card-topic px-2 pt-3">
                        Active Profiles
                      </Card.Title>
                      <Card.Text className="card-para px-3">
                        {activeProfilesCount !== null ? (
                          <span>{activeProfilesCount}</span>
                        ) : (
                          0
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                {/* </CardWrapper> */}
              </Link>
            </div>
            <div className="col-md-3 my-2">
              <Card id="dashboard-card">
                <Card.Body>
                  <div className="avatar profile-success-icon  bg-light-sucess mx-2">
                    <div className="avatar-content">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-user-check"
                      >
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <polyline points="17 11 19 13 23 9"></polyline>
                      </svg>
                    </div>
                  </div>
                  <Card.Title className="card-topic px-2 pt-3">
                    New Profiles
                  </Card.Title>
                  <Card.Text className="card-para px-3">
                    {requestedProfiles.length}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-3 my-2">
              <Card id="dashboard-card">
                <Card.Body>
                  <div className="avatar profile-error-icon bg-light-error mx-2">
                    <div className="avatar-content">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-user-x"
                      >
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="18" y1="8" x2="23" y2="13"></line>
                        <line x1="23" y1="8" x2="18" y2="13"></line>
                      </svg>
                    </div>
                  </div>
                  <Card.Title className="card-topic px-2 pt-3">
                    Hided Profiles
                  </Card.Title>
                  <Card.Text className="card-para px-3">
                    {hidedProfileCount !== null ? (
                      <span>{hidedProfileCount}</span>
                    ) : (
                      0
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-3 my-2">
              <Card id="dashboard-card">
                <Card.Body>
                  <div className="avatar profile-error-icon bg-light-error mx-2">
                    <div className="avatar-content">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-user-x"
                      >
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="18" y1="8" x2="23" y2="13"></line>
                        <line x1="23" y1="8" x2="18" y2="13"></line>
                      </svg>
                    </div>
                  </div>
                  <Card.Title className="card-topic px-2 pt-3">
                    Deleted Profiles
                  </Card.Title>
                  <Card.Text className="card-para px-3">
                    {deletedProfilesCount !== null ? (
                      <span>{deletedProfilesCount}</span>
                    ) : (
                      0
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ModeratorHome;
