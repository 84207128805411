import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { useModerator } from "../../../context/moderatorContext";
import {
  getHoldedProfiles,
  getProfile,
  unholdProfile,
  getMemberDetailsById
} from "../../../services/profileService";
import FullProfileDetails from "../profileRequest/fullProfileDetails";
import "./holdedProfiles.css";
import { toast } from 'react-toastify';
import ContentLoader from './../../common/contentLoader';

const HoldedProfile = (props) => {
  const { myProfile } = useModerator();
  const { holdedProfiles, setHoldedProfiles } = useModerator();
  const [holdedProfile, setHoldedProfile] = useState();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)
  const history = useHistory();
  const [member,setMember] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(async () => {
    setLoading(true);
    const { data } = await getProfile(props.location.state.profile.id);
    const member = await getMemberDetailsById(data.externalId);
    setMember(member.data);
    setHoldedProfile(data);
    setLoading(false);
  }, []);

  const handleUnhold = async (id) => {
    const response = await unholdProfile(id, myProfile.id);
    if (response.status == 204) {
      // handleShow();
      toast.success("This Profile Undo On Hold Successfully!!!")
      const holdedProfilesResponse = await getHoldedProfiles();
      if ((holdedProfilesResponse.status = 200)) {
        setHoldedProfiles(holdedProfilesResponse.data);
      }
      history.replace(`/moderator/holded-profiles`);
    } else {
      toast.error("Unable to Undo On Hold this profile");
    }
  };

  return (
    <>
      {loading && <ContentLoader className="spinner" />}
      {!loading &&
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Matrimony</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>Do you want to Undo On Hold this Profile?</Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  handleUnhold(holdedProfile.id)
                }}
              >
                Yes
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleClose();
                }}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>

          {holdedProfile && (
            <div className="home">
              <div className="col-md-10">
                <div className="card m-2">
                  <FullProfileDetails profile={holdedProfile} {...props} member={member} />
                  <button
                    className="btn btn-primary"
                    onClick={() => handleShow()}
                  >
                    Undo On Hold
                  </button>
                  {/* <button
                className="btn btn-primary"
                onClick={() => handleReject(holdedProfile.id)}
              >
                Reject
              </button> */}
                  <br />
                </div>
              </div>
            </div>
          )}
        </>
      }
    </>
  );
};

export default HoldedProfile;
