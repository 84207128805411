import { ExitToApp } from "@material-ui/icons";
import React from "react";
import "./topBar.css";
const TopBar = () => {

  return (
    <>
     
            <ExitToApp style={{display: "none"}} className="topbarIconContainer" />
         
         
    </>
  );
};

export default TopBar;
