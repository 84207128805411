import React from "react";
import "./approveRejectButton.css";

const ApproveRejectButton = (props) => {
  return (
    <div>
      <label class="switch">
        <input
          type="checkbox"
          id="togBtn"
          onClick={(e) => {
            props.onToggle(props.field.id, e.target.checked);
          }}
        />
        <div class="slider round">
          <span class="on">Approved</span>
          <span class="off">Rejected</span>
        </div>
      </label>
    </div>
  );
};

export default ApproveRejectButton;
