import { DataGrid } from "@material-ui/data-grid";
import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { useModerator } from "../../../context/moderatorContext";
import {
  approveHoroscope,
  approvePhoto,
  getHoroscopePhotos,
  getNewPhotos,
  rejectHoroscope,
  rejectPhoto,
} from "../../../services/photoService";
import { getProfile } from "../../../services/profileService";
import ContentLoader from "../../common/contentLoader";
import "./photoRequest.css";
import LocationDetails from "../profileRequest/locationDetails";
import ReviewModal from "./ReviewModalHorascope";

const HoroscopeRequest = () => {
  const [show, setShow] = useState(false);
  const {
    horoscopePhotos,
    setHoroscopePhotos,
    setPhotoCount,
    moderatorLoading,
  } = useModerator();
  const [image, setImage] = useState();
  const [count, setCount] = useState(0);
  const [profile, setProfile] = useState();
  const [confirmationShow, setConfirmationShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileIdName, setprofileIdName] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleConfirmationClose = () => setConfirmationShow(false);
  const handleConfirmationShow = () => setConfirmationShow(true);
  const [timezoneOffset, setTimezoneOffset] = useState(null);
  const [utcDate, setUtcDate] = useState(null);

  const [showReviewModal, setShowReviewModal] = useState(false);
  const [option, setOption] = useState("");

  const handleCloseReviewModal = () => setShowReviewModal(false);
  const handleShowReviewModal = () => setShowReviewModal(true);

  //getting the time zone difference
  useEffect(() => {
    const offsetInMinutes = new Date().getTimezoneOffset();
    setTimezoneOffset(offsetInMinutes);
  }, []);

  //converting UTC time to local time
  function convertToLocalTime(utcDate) {
    const UtcDate = new Date(utcDate);
    const localDate = new Date(UtcDate.getTime() - timezoneOffset * 60000);
    const localTimeString = localDate.toLocaleString();
    return localTimeString;
  }

  //get age
  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const columns = [
    { field: "profileId", headerName: "Profile ID", width: 200 },
    {
      field: "photo",
      headerName: "Photo",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            <img
              className="userListImg"
              src={`data:image;base64,${params.row.fileContents}`}
              alt=""
            />
          </div>
        );
      },
    },
    //{ field: "uploadedAt", headerName: "Uploade At", width: 300 },
    {
      field: "uploadedAt",
      headerName: "Uploade At",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {convertToLocalTime(params.row.uploadedAt)}
          </div>
        );
      },
    },
    {
      field: "review",
      headerName: "Review",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {/* <Link
              to={{
                pathname: `/moderator/profile-update-request/${params.row.id}`,
                state: { profile: params.row },
              }}
            > */}
            <button
              className="userListEdit"
              onClick={async () => {
                setLoading(true);
                setShowReviewModal(true);
                setImage(params.row);
                const response = await getProfile(params.row.profileId);
                if (response.status === 404) {
                  if (params.row.profileId === profileIdName) {
                    setLoading(false);
                    setShowReviewModal(false);
                    return;
                  }
                  toast.error("This profile is deleted");
                  setLoading(false);
                  setShowReviewModal(false);
                  setprofileIdName(params.row.profileId);
                }
                if (response.status === 200) {
                  setProfile(response.data);
                  setLoading(false);
                }
              }}
            >
              Review
            </button>
            {/* </Link> */}
          </>
        );
      },
    },
  ];

  return (
    <>
      {!moderatorLoading && (
        <>
          <div className="userList py-5 px-4 mt-5">
            <h4 className="table-title">Uploaded Photos</h4>
            <DataGrid
              rows={horoscopePhotos}
              disableSelectionOnClick
              columns={columns}
              pageSize={8}
              // checkboxSelection
            />
          </div>

          {loading && <ContentLoader className="spinner" />}
          {profile && !loading && (
            <>
              <ReviewModal
                show={showReviewModal}
                handleClose={handleCloseReviewModal}
                profile={profile}
                image={image}
                setOption={setOption}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default HoroscopeRequest;
