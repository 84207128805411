import { Col, Row, Table } from "react-bootstrap";
import LocationCityIcon from '@mui/icons-material/LocationCity';

const LocationDetails = (props) => {
  const { profile } = props;
  const { country, state, city, residentialStatus, ethnicOrigin, religion, community, cast, castNoBar, motherTongue } =
    profile.location;
  const growUpInCountries = profile.growUpInCountries;

  function renderWithComma(value) {
    return value ? `${value}, ` : 'n/A';
  }

  return (
    <div>
      <div className="topic"><LocationCityIcon />&nbsp;Location and Religious Background</div>
      <div className="content2">
        <div>Country:
          <span className="m-2">
            {country.name || "N/A"}
          </span>
        </div>

        <div>State:
          <span className="m-2">
            {state?.name || "N/A"}
          </span>
        </div>

        <div>City:
          <span className="m-2">
            {city?.name || "N/A"}
          </span>
        </div>

        <div>Residential Status:
          <span className="m-2">
            {residentialStatus?.name || "N/A"}
          </span>
        </div>

        <div>Ethnic Origin:
          <span className="m-2">
            {ethnicOrigin?.name || "N/A"}
          </span>
        </div>

        <div> Grow Up In:
          {growUpInCountries.map((gui) => (
            <span
              key={gui?.id}
              className="m-2"
            >
              {renderWithComma(gui?.name)}
            </span>
          ))}
        </div>

        <div>Religion:
          <span className="m-2">
            {religion?.name || "N/A"}
          </span>
        </div>

        <div>Community:
          <span className="m-2">
            {community?.name || "N/A"}
          </span>
        </div>

        <div>Mother Tongue:
          <span className="m-2">
            {motherTongue?.name || "N/A"}
          </span>
        </div>

        <div>Caste:
          <span className="m-2">
            {cast?.name || "N/A"}
          </span>
        </div>

        <div>Caste No Bar:
          <span className="m-2">
            {castNoBar ? "Yes" : "No"}
          </span>
        </div>

        <div>Spoken Languages:
          {profile.profileLanguages?.map((language) => (
            <span
              key={language?.id}
              className="m-2"
            >
              {renderWithComma(language?.name)}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocationDetails;
