import React from "react";
import { Route, Switch } from "react-router";
import PrivateRoute from "../privateRoute";
import Profile from "./allProfiles/profile";
import Profiles from "./allProfiles/profiles";
import SideBar from "./dashboard/sideBar/SideBar";
import TopBar from "./dashboard/topBar/topBar";
import DeletedProfile from "./deletedProfiles/deletedProfile";
import DeletedProfiles from "./deletedProfiles/deletedProfiles";
import HoldedProfile from "./holdedProfiles/holdedProfile";
import HoldedProfiles from "./holdedProfiles/holdedProfiles";
import "./moderator.css";
import ModeratorHome from "./moderatorHome";
import Member from "./member";
import ModeratorRequestForm from "./moderatorRequestForm";
import OwnProfile from "./ownProfile/ownProfile";
import UpdatedEmailVerification from "./ownProfile/updatedEmailVerification";
import PhotoRequest from "./photoRequest/photoRequest";
import RequestedProfile from "./profileRequest/requestedProfile";
import RequestedProfiles from "./profileRequest/requestedProfiles";
import RoleInvitation from "./roleInvitation";
import ProfileUpdateRequest from "./updateProfileRequest/profileUpdateRequest";
import ProfileUpdateRequests from "./updateProfileRequest/profileUpdateRequests";
import VerificationForm from "./verificationForm";
import ManageAttributes from "./manageAttributes/manageAttributes";
import ProfileMemberAudit from "./manageAttributes/ProfilememberAudit";
import ModeratorAudit from "./manageAttributes/ModeratorAudit";
import { AttributesProvider } from "../../context/attributeContext";
import ActiveProfile from "./activeProfile";
import ManageModerator from "../admin/ManageModerator";
import HoroscopeRequest from "./photoRequest/horoscopeRequest";
import ProfileAudit from "./manageAttributes/ProfileAudit";

function Moderator() {
  return (
    <>
      <TopBar />
      <div className="moderator-container">
        <SideBar />
        <Switch>
          <PrivateRoute exact path="/moderator" component={ModeratorHome} />
          <Route exact path="/moderator/profiles" component={Profiles} />
          <Route exact path="/moderator/profile/:id" component={Profile} />
          <Route exact path="/moderator/profileAudit" component={ProfileAudit} />
          <Route
            exact
            path="/moderator/member"
            component={Member}
          />
          <Route
            exact
            path="/moderator/moderator-request-form"
            component={ModeratorRequestForm}
          />
          <Route
            exact
            path="/moderator/holded-profiles"
            component={HoldedProfiles}
          />
          <Route
            exact
            path="/moderator/requested-profiles"
            component={RequestedProfiles}
          />
          <Route
            exact
            path="/moderator/role-invitation"
            component={RoleInvitation}
          />
          <Route
            exact
            path="/moderator/verification-form"
            component={VerificationForm}
          />
          <Route
            exact
            path="/moderator/requested-profile/:id"
            component={RequestedProfile}
          />
          <Route
            exact
            path="/moderator/profile-update-requests"
            component={ProfileUpdateRequests}
          />
          <Route
            exact
            path="/moderator/profile-update-request/:id"
            component={ProfileUpdateRequest}
          />
          <Route path="/moderator/manage-moderator" component={ManageModerator} />
          <Route
            exact
            path="/moderator/holded-profile/:id"
            component={HoldedProfile}
          />
          <Route
            path="/moderator/deleted-profiles"
            component={DeletedProfiles}
          />
          <Route
            exact
            path="/moderator/deleted-profile/:id"
            component={DeletedProfile}
          />
          <Route exact path="/moderator/account" component={OwnProfile} />
          <Route
            exact
            path="/moderator/account/confirm-mail"
            search="?user-id=:id&token=:token"
            component={UpdatedEmailVerification}
          />
          <Route
            exact
            path="/moderator/member-audit"
            component={ProfileMemberAudit}
          />
          <Route
            exact
            path="/moderator/moderator-audit"
            component={ModeratorAudit}
          />
          <Route path="/moderator/photo-requests" component={PhotoRequest} />
          <Route path="/moderator/horoscope-requests" component={HoroscopeRequest} />
          <AttributesProvider>
            <Route
              exact
              path="/moderator/manage-attributes"
              component={ManageAttributes}
            />
            <Route path="/moderator/active-profile" component={ActiveProfile} />
          </AttributesProvider>
        </Switch>
      </div>
    </>
  );
}

export default Moderator;
