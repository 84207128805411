// ContentLoader.js
import React from "react";
import { Spinner } from "react-bootstrap";
import "./contentLoader.css";

const ContentLoader = () => {
  return (
    <div className="spinner">
      <Spinner animation="grow" variant="danger" />
      &nbsp;
      &nbsp;
      &nbsp;
      <>Loading... </>
    </div>

  );
};

export default ContentLoader;
