import React, { useEffect, useState } from "react";
import { Carousel, Col, Row, Table } from "react-bootstrap";
import { getAllProfilePhotos } from "../../../services/profileService";
import { toast } from "react-toastify";
import './profileDetails.css';

const ProfilePhotos = (props) => {

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    async function getAllPhotos() {
      const data = await getAllProfilePhotos(props.profile.id);
      if (data.status === 200) {
        setPhotos(data.data);
      } else {
        toast.error("No photos uploaded");
      }
    }
    getAllPhotos();
  }, [props.profile.id]);

  return (
    <>
      <div className="topic">Profile photos</div>
      <div className="photos">
        {photos && photos.length !== 0 ? (
          <Carousel slide={false}>
            {photos.map((profilePhoto, index) => (
              <Carousel.Item key={profilePhoto.id}>
                <img
                  className="profileimg"
                  src={`data:image;base64,${profilePhoto.fileContents}`}
                  key={profilePhoto.id}
                  alt="First slide"
                />
              </Carousel.Item>
            ))
            }
          </Carousel>
        ) : "No photos uploaded"}
      </div>
    </>
  );
};

export default ProfilePhotos;
