import { Col, Row, Table } from 'react-bootstrap';
import WorkIcon from '@mui/icons-material/Work';

const ProfessionalDetails = (props) => {
  const profile = props.profile;

  return (
    <div>
      <div className="topic"><WorkIcon />&nbsp;Professional Details</div>
      <div className="content2">
        <div>Employer Name:
          <span className='m-2'>
            {profile?.educationAndProfession?.employerName || "-"}
          </span>
        </div>

        <div>Job Sector:
          <span className='m-2'>
            {profile?.educationAndProfession?.jobSector?.name}
          </span>
        </div>

        <div>Qualification:
          <span className='m-2'>
            {profile?.educationAndProfession?.highestQualification?.name || "-"}
          </span>
        </div>

        <div>Profession:
          <span className='m-2'>
            {profile?.educationAndProfession?.profession?.name || "-"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalDetails;
