import { Col, Row, Table } from "react-bootstrap";
import './profileDetails.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const BasicDetails = (props) => {
  const profile = props.profile;
  return (
    <div>
      <div className="topic"> <AccountCircleIcon />&nbsp; Basic Details</div>
      <div className="content2">
        <div>Created By:
          <span className="m-2">
            {profile?.createdBy?.name}
          </span>
        </div>

        <div>Profile Name:
          <span className="m-2">{profile?.profileName}</span>
        </div>

        <div>First Name:
          <span className="m-2">{profile?.firstName}</span>
        </div>

        <div>Surname:
          <span className="m-2">{profile?.surname}</span>
        </div>

        <div> DoB:
          <span className="m-2">{profile?.dob}</span>
        </div>

        <div>Gender:
          <span className="m-2">{profile?.gender?.name}</span>
        </div>

        <div>Height:
          <span className="m-2">{profile?.height?.name}</span>
        </div>

        <div>Weight:
          <span className="m-2">{profile?.weight}</span>
        </div>


        <div>Has Children:
          <span className="m-2">{profile.hasChildren ? "Yes" : "No"}</span>
        </div>

        {profile.hasChildren &&
          <div>Number of Children:
            <span className="m-2">{profile?.numberOfKids}</span>
          </div>}

        <div>Marital Status:
          <span className="m-2">
            {profile.maritalStatus?.name}
          </span>
        </div>

        <div>Dietary Habit:
          <span className="m-2">
            {profile.dietHabbit?.name}
          </span>
        </div>

        <div>Smoking  Habit:
          <span className="m-2">
            {profile.smokingHabbit?.name}
          </span>
        </div>

        <div>Drinking Habit:
          <span className="m-2">
            {profile.drinkingHabbit?.name}
          </span>
        </div>
      </div>

    </div>

  );
};

export default BasicDetails;
