import React, { useState, useEffect } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import ConfirmationModal from "./ConfirmationModal";
import '../../moderator/moderator.css';

//get age
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const ReviewModal = ({ show, handleClose, profile, image, setOption }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [newoption, setNewOption] = useState("");

  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modal-body">
          <Table borderless>
            <tbody>
              <th>
                <h4>{profile?.firstName || "-"}</h4>
                <h6>{profile?.gender?.name}</h6>
              </th>
              <tr>
                <td>
                  Age:{getAge(profile?.dob)}, {profile?.height?.name}
                </td>
                <td>{profile?.maritalStatus?.name}</td>
              </tr>
              <tr>
                <td>{profile?.location.religion?.name || "-"}</td>
                <td>
                  {profile?.location.city?.name},{" "}
                  {profile?.location.state?.name}
                </td>
              </tr>
              <tr>
                <td>{profile?.location.motherTongue?.name}</td>
                <td>
                  {profile?.educationAndProfession?.profession?.name || "-"}
                </td>
                {/* <td>{profile.religiousBackground.motherTongue.name}</td> */}
              </tr>
            </tbody>
          </Table>
          {image && (
            <div className="upload-photo">
              <img
                src={`data:image;base64,${image.fileContents}`}
                key={image}
                alt="UploadedImage"
                className="img-thumbnail img-fluid uploaded-img sub"
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setNewOption("ACCEPT");
              setShowConfirmationModal(true);
              handleClose();
            }}
          >
            Approve
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setNewOption("REJECT");
              setShowConfirmationModal(true);
              handleClose();
            }}
          >
            Reject
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        option={newoption}
        image={image}
      />
    </>
  );
};

export default ReviewModal;
