import { Col, Row, Table } from 'react-bootstrap';
import './profileDetails.css';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

const HobbyAndInterestDetails = (props) => {
  const {
    profileHobbies,
    profileInterests,
    profileMusicGenres,
    profileMovieGenres,
    profileCuisines,
    profileSportsAndFitnessActivities,
    profileDressStyles,
  } = props.profile;

  function renderWithComma(value) {
    return value ? `${value}, ` : 'n/A';
  }

  return (
    <div>

      <div className="topic"> <SportsEsportsIcon />&nbsp; Hobby and Interest Details</div>
      <div className="content2">
        <div>Hobbies:
          {profileHobbies.length === 0 ? "-" : profileHobbies.map((h) => (
            <span key={h.id} className='m-2'>
              {renderWithComma(h.name)}
            </span>
          ))}
        </div>

        <div>Interests:
          {profileInterests.length === 0 ? "-" : profileInterests.map((i) => (
            <span key={i.id} className='m-2'>
              {renderWithComma(i.name)}
            </span>
          ))}
        </div>

        <div>Preferred Music Genres:
          {profileMusicGenres.length === 0 ? "-" : profileMusicGenres.map((mg) => (
            <span key={mg.id} className='m-2'>
              {renderWithComma(mg.name)}
            </span>
          ))}
        </div>

        <div>Preferred Movie Genres:
          {profileMovieGenres.length === 0 ? "-" : profileMovieGenres.map((mg) => (
            <span key={mg.id} className='m-2'>
              {renderWithComma(mg.name)}
            </span>
          ))}
        </div>

        <div>Favourite Cuisines:
          {profileCuisines.length === 0 ? "-" : profileCuisines.map((c) => (
            <span key={c.id} className='m-2'>
              {renderWithComma(c.name)}
            </span>
          ))}
        </div>

        <div> Sports and Fitness Activities:
          {profileSportsAndFitnessActivities.length === 0 ? "-" : profileSportsAndFitnessActivities.map((safa) => (
            <span
              key={safa.id}
              className='m-2'
            >
              {renderWithComma(safa.name)}
            </span>
          ))}
        </div>

        <div>Preferred Dress Styles:
          {profileDressStyles.length === 0 ? "-" : profileDressStyles.map((ds) => (
            <span key={ds.id} className='m-2'>
              {renderWithComma(ds.name)}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HobbyAndInterestDetails;
