import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { MicrosoftLoginAgent } from "../services/auth/msal";
import { getMe } from "../services/userService";

const NavBar = () => {
  const { account, setAccount } = useAuth();
  const [moderator, setModerator] = useState(false);
  const [admin, setAdmin] = useState(false);
  const history = useHistory();

  const onLogout = async () => {
    const successfulLogout = await MicrosoftLoginAgent.logout();
    if (successfulLogout) {
      const account = MicrosoftLoginAgent.getAccount();
      setAccount(account);
      history.push("/");
    }
  };

  const onLogin = async () => {
    const successfulLogin = await MicrosoftLoginAgent.login();
    if (successfulLogin) {
      const account = MicrosoftLoginAgent.getAccount();
      setAccount(account);
    }
  };

  useEffect(async () => {
    const response = await getMe("me");
    if (response.status == 200) {
      setModerator(true);
    }
  }, [account]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
          Vivaakam - Moderator Web
          </Link>
          {account && admin && (
            <Link className="navbar-brand" to="/admin">
              Admin
            </Link>
          )}
          {/* {account && moderator && (
            <Link className="navbar-brand" to="/moderator">
              Moderator
            </Link>
          )} */}
           <Nav
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            {account && (
              <Nav.Item>
                <Nav.Link>
                  {account?.name === "unknown" ? "Welcome!" : account?.name}
                </Nav.Link>

                <Nav.Link onClick={onLogout}>Sign out</Nav.Link>
              </Nav.Item>
            )}
            {!account && (
              <Nav.Item>
                <Nav.Link onClick={onLogin}>Sign in!</Nav.Link>
                {/* <Link to="/sign-in">Sign in</Link> */}
              </Nav.Item>
            )}
          </Nav>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
