import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useModerator } from "../../../context/moderatorContext";
import {
  getProfile,
  getRequestedProfiles,
  publishProfile,
  rejectProfile,
  getMemberDetailsById
} from "../../../services/profileService";
import ContentLoader from "../../common/contentLoader";
import FullProfileDetails from "./fullProfileDetails";
import "./requestedProfiles.css";
import { toast } from 'react-toastify';

const RequestedProfile = (props) => {
  const { myProfile } = useModerator();
  const { setRequestedProfiles } = useModerator();
  const [requestedProfile, setRequestedProfile] = useState();
  const [member, setMember] = useState();
  const [option, setOption] = useState();
  const [show, setShow] = useState(false);
  const [confirmationShow, setConfirmationShow] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleConfirmationClose = () => setConfirmationShow(false);
  const handleConfirmationShow = () => setConfirmationShow(true);

  useEffect(async () => {
    setLoading(true);
    const { data } = await getProfile(props.location.state.profile.id);
    const member = await getMemberDetailsById(props.location.state.profile.externalId);
    setMember(member.data);
    setRequestedProfile(data);
    setLoading(false);
  }, []);

  const handleAccept = async (id) => {
    if (requestedProfile.maritalStatus === null) {
      toast.warn("Profile incomplete!");
      handleConfirmationClose();
      return;
    }
    const response = await publishProfile(id, myProfile.id);
    if (response.status == 201) {
      toast.success("This Profile Accepted Successfully!!!");
      const requestedProfilesResponse = await getRequestedProfiles();
      if ((requestedProfilesResponse.status = 200)) {
        setRequestedProfiles(requestedProfilesResponse.data);
      }
      setOption("ACCEPT");
      history.replace(`/moderator/requested-profiles`);
      // handleShow();
    } else {
      toast.error("Unable to accept this profile");
    }
  };

  const handleReject = async (id) => {
    const response = await rejectProfile(id, myProfile.id);
    if (response.status == 201) {
      toast.success("This Profile Rejected Successfully!!!");
      const requestedProfilesResponse = await getRequestedProfiles();
      if ((requestedProfilesResponse.status = 200)) {
        setRequestedProfiles(requestedProfilesResponse.data);
      }
      setOption("REJECT");
      history.replace(`/moderator/requested-profiles`);
      // handleShow();
    } else {
      toast.error("Unable to reject this profile");
    }
  };
  return (
    <>
      {loading && <ContentLoader className="spinner" />}
      {!loading &&
        <>
          <Modal show={confirmationShow} onHide={handleConfirmationClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              {option == "ACCEPT" && "Do you want to accept this Profile?"}
              {option == "REJECT" && "Do you want to reject this Profile?"}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  if (option == "ACCEPT") {
                    handleAccept(requestedProfile.id);
                  } else if (option == "REJECT") {
                    handleReject(requestedProfile.id);
                  }
                }}
              >
                Yes
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleConfirmationClose();
                }}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
          {requestedProfile && (
            <div className="home">
              <div className="col-md-10">
                <div className="card m-2">
                  {/* <div className="card m-2"> */}
                  <FullProfileDetails profile={requestedProfile} {...props} member={member} />
                  <button
                    className="btn btn-primary"

                    onClick={() => {
                      setOption("ACCEPT");
                      handleConfirmationShow();
                    }
                    }
                  >
                    Accept
                  </button>
                  <br />
                  <button
                    className="btn btn-primary"
                    // onClick={() => handleReject(requestedProfile.id)}
                    onClick={() => {
                      setOption("REJECT");
                      handleConfirmationShow();
                    }
                    }
                  >
                    Reject
                  </button>
                  <br />
                </div>
              </div>
            </div>
          )}
        </>
      }
    </>
  );
};

export default RequestedProfile;
