import React from "react";
import "./../moderator/moderatorDashboard.css";

const AdminDashboard = () => {
  return (
    <div>
      <nav class="navbar navbar-dark fixed-top bg-primary flex-md-nowrap p-0 shadow">
        <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="#">
          Administrator
        </a>
        <input
          type="text"
          class="form-control form-control-primary w-100"
          placeholder="Search..."
        />
        <ul class="navbar-nav px-3">
          <li class="nav-item text-nowrap">
            <a class="nav-link" href="/home">
              Logout
            </a>
          </li>
        </ul>
      </nav>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-2 bg-light d-none d-md-block sidebar">
            <div class="left-sidebar">
              <ul class="nav flex-column sidebar-nav">
                <li class="nav-item">
                  <a class="nav-link active" href="/admin/home">
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/admin/moderator-invitation">
                    Invite Moderator
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/admin/user-role-requests">
                    User Role Requests
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/admin/user-role-revocation">
                    User Role Revocation
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/admin/home">
                    Setting
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
