import { Route, Switch } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Admin from "./components/admin/admin";
import Home from "./components/home";
import Moderator from "./components/moderator/moderator";
import PrivateRoute from "./components/privateRoute";
import { AuthProvider } from "./context/authContext";
import { ModeratorProvider } from "./context/moderatorContext";
import { EventType, InteractionType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { b2cPolicies, MicrosoftLoginAgent } from "./services/auth/msal";

function App() {
  return (
    <>
      <MsalProvider instance={MicrosoftLoginAgent.msalInstance}>
        <AuthProvider>
        <ToastContainer />
          <ModeratorProvider>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/admin" component={Admin} />
              <PrivateRoute path="/moderator" component={Moderator} />
            </Switch>
          </ModeratorProvider>
        </AuthProvider>
      </MsalProvider>
    </>
  );
}

export default App;
