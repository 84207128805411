import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import './profileDetails.css';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';

const FamilyDetails = (props) => {
  return (
    <>
      <div >
        <div className="topic"><FamilyRestroomIcon />&nbsp; Family Details</div>
        <div className="content2">
          <div>Father's status:
            <span className="m-2">{props.profile?.family?.fatherStatus?.name || "-"}</span>
          </div>

          <div>Mother's status:
            <span className="m-2">{props.profile?.family?.motherStatus?.name || "-"}</span>
          </div>

          <div>No of sisters:
            <span className="m-2">{props.profile?.family?.numberOfSisters || "-"}</span>
          </div>

          <div>No of Sisters married:
            <span className="m-2">{props.profile?.family?.numberOfSistersMarried || "-"}</span>
          </div>

          <div>No of brothers:
            <span className="m-2">{props.profile?.family?.numberOfBrothers || "-"}</span>
          </div>

          <div>No of Brothers married:
            <span className="m-2">{props.profile?.family?.numberOfBrothersMarried || "-"}</span>
          </div>

        </div>
      </div>
    </>
  );
};

export default FamilyDetails;
